import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth';

export const GraphQlNetworkErrorInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return next(request).pipe(
    catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
      if (error.status === 401) {
        return handleRefreshToken({ request, next, error }, authService);
      }

      if (error.status === 451) {
        router.navigate(['/451']);
        return next(request);
      }

      throw error;
    }),
  );
};

const handleRefreshToken = (
  request: {
    request: HttpRequest<any>;
    next: HttpHandlerFn;
    error: HttpErrorResponse;
  },
  authService: AuthService,
): Observable<HttpEvent<any>> => {
  return authService.refreshAccessToken().pipe(
    catchError(() => {
      authService.logout();
      throw request.error;
    }),
    switchMap(() => {
      if (authService.isAuthenticated()) {
        const header = authService.getAuthorizationHeader();
        const headers = request.request.headers.set('Authorization', header);
        return request.next(request.request.clone({ headers }));
      }
      throw request.error;
    }),
  );
};
