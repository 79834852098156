<div class="preview-content position-relative">
  @if (showBackground) {
    <div class="background-image" [ngStyle]="{ 'background-image': backgroundImage }"></div>
  }
  @if (tokenFactoryIdCardEntity) {
    <ultra-id-card-content-header
      [idCardEntity]="tokenFactoryIdCardEntity"
      [companyName]="companyName"
      [config]="idCardContentHeaderConfig"
      (closed)="closed.emit()"></ultra-id-card-content-header>
  }
  <ultra-tab-group
    headerPosition="below"
    tabIndicator="bellow"
    indicatorSize="thick"
    [padContent]="false"
    (tabSelected)="onTabChange($event)">
    <ultra-tab>
      <ng-template ultraTabLabel>
        <span ultraAutotestAttribute="token-preview-overview-tab">Overview</span>
      </ng-template>
      <ng-template ultraTabContent>
        @if (tokenFactoryIdCardEntity) {
          <ultra-id-card-overview [idCardEntity]="tokenFactoryIdCardEntity"></ultra-id-card-overview>
        }
      </ng-template>
    </ultra-tab>
    @if (isAuthenticated) {
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-content-tab">Content</span>
        </ng-template>
        <ng-template ultraTabContent>
          @if (tokenFactoryIdCardEntity) {
            <ultra-id-card-content [idCardEntity]="tokenFactoryIdCardEntity"></ultra-id-card-content>
          }
        </ng-template>
      </ultra-tab>
    }
    <ultra-tab>
      <ng-template ultraTabLabel>
        <span ultraAutotestAttribute="token-preview-preview-tab">Preview</span>
      </ng-template>
      <ng-template ultraTabContent>
        @if (tokenFactoryIdCardEntity) {
          <ultra-media-carousel
            [multiMedia]="tokenFactoryIdCardEntity?.medias?.imageGalleryList"
            theme="token-preview"
            [expandable]="true"></ultra-media-carousel>
        }
      </ng-template>
    </ultra-tab>
    @if (isAuthenticated) {
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-properties-tab">Properties</span>
        </ng-template>
        <ng-template ultraTabContent>
          @if (showTokenFactoryProperties) {
            <ultra-id-card-properties
              [activeTab]="properties.GEOFENCING"
              [idCardEntity]="tokenFactoryIdCardEntity"
              [geofencingData]="geofencingData$ | async">
            </ultra-id-card-properties>
          } @else {
            @if (uniqFactoryIdEntityCard) {
              <ultra-id-card-properties
                [idCardEntity]="uniqFactoryIdEntityCard"
                [activeTab]="getUniqActivePropertyTab()"
                [showTradability]="true"
                [showTransferability]="true"
                [showGeofencing]="false"></ultra-id-card-properties>
            }
          }
        </ng-template>
      </ultra-tab>
    }
  </ultra-tab-group>

  <div class="d-flex position-absolute preview-content-actions">
    @if (uniqFactoryIdEntityCard) {
      <ultra-preview-token-header-properties
        class="d-flex align-items-center px-3 cursor-pointer"
        [idCardEntity]="uniqFactoryIdEntityCard"
        (propertyClick)="handlePropertyQuickAccess($event)">
      </ultra-preview-token-header-properties>
    }
    @if (isTokenFactoryAlreadyInLibrary) {
      <a
        class="preview-price preview-price__purchased rounded f-size-12 d-flex d-flex-center cursor-pointer"
        routerLink="your-library">
        <span i18n>Already Purchased</span>
      </a>
    } @else {
      @if ((tokenFactory && tokenFactory.freeToPlay) || tokenFactory?.livePrices?.[0]?.amount) {
        <div class="d-flex gap-2 justify-content-center">
          @if (!tokenFactory.freeToPlay && tokenFactory?.livePrices?.[0]?.discount > 0) {
            <ultra-game-discount [discountBasis]="tokenFactory.livePrices[0].discount"></ultra-game-discount>
          }
          <button
            class="btn preview-price rounded f-size-12 d-flex d-flex-center"
            [ngClass]="{
              'preview-price__currency': tokenFactory?.livePrices && !tokenFactory?.freeToPlay,
              'preview-price__free': tokenFactory?.freeToPlay
            }"
            [disabled]="buyingOptionRestricted"
            (click)="emitPriceButtonClicked($event)">
            @if (tokenFactory.freeToPlay) {
              <span i18n>Free</span>
            } @else {
              @if (tokenFactory.livePrices[0]?.discount > 0) {
                <ultra-game-price-discount [price]="tokenFactory.livePrices[0]"></ultra-game-price-discount>
              } @else {
                <span>
                  {{ getPrice(tokenFactory.livePrices[0]) }}
                </span>
              }
            }
          </button>
        </div>
      } @else {
        <span class="preview-price preview-price--empty rounded f-size-12 d-flex d-flex-center"> Details </span>
      }
    }
  </div>
</div>
