import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Paginatable, Pagination } from '../../models';
import { IUniqFactory, UniqFactoryFilterInput } from '../uniq-factory.interface';

import { UniqFactoryGqlService } from './uniq-factory-gql.service';

@Injectable({
  providedIn: 'root',
})
export class UniqFactoryService {
  constructor(private uniqFactoryGqlService: UniqFactoryGqlService) {}

  getUniqFactoryById(id: string): Observable<IUniqFactory> {
    return this.uniqFactoryGqlService.getUniqFactoryById(id);
  }

  getUniqFactories(
    filter: UniqFactoryFilterInput,
    pagination: Pagination = { limit: 7, skip: 0 }
  ): Observable<Paginatable<IUniqFactory[]>> {
    return this.uniqFactoryGqlService.getUniqFactories(filter, pagination);
  }
}
