import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService, ApolloClientName } from '@ultra/core/graphql';

import { ApiSharedGqlConfig } from '../../../graphql-client';
import { LanguageInterface } from '../../../interfaces';
import { IGeofencingData } from '../../../models/geofencing';

import { GeofencingServiceInterface } from './geofencing.service.interface';

@Injectable()
export class GeofencingGqlService implements GeofencingServiceInterface {
  private apiService = inject(ApiGraphQLService);

  getGeofencingData(): Observable<IGeofencingData> {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.GEOFENCING_DATA, null, {
        apolloClient: ApolloClientName.REFERENTIAL,
        cache: false,
      })
      .pipe(map((result) => result.data));
  }

  getLanguagesList(): Observable<LanguageInterface[]> {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.LANGUAGES_LIST, {}, { apolloClient: ApolloClientName.REFERENTIAL })
      .pipe(
        map((result) => {
          return result.data.languages;
        }),
      );
  }
}
