import { Hit, MultipleQueriesResponse, SearchResponse } from '@algolia/client-search';

import { CurrencyType } from '@ultra/core/models';

import { ITokenFactoriesByGame, ITokenFactory, ITokenPrice } from '../../models';

export function algoliaSingleResponseAdapter(
  response: MultipleQueriesResponse<AlgoliaGameFactoryModel>,
  currency: CurrencyType
): ITokenFactory {
  const result = algoliaResponseAdapter(response.results[0]['hits'], currency);
  return result[0];
}

export function algoliaResponseAdapter(hits: Hit<AlgoliaGameFactoryModel>[], currency: CurrencyType): ITokenFactory[] {
  return hits
    .filter((hit) => hit.freeToPlay || hit.prices.some((price) => price.currency === currency))
    .map((hit) => ({
      ...hit,
      livePrices: adaptPrices(hit.prices, currency),
    }));
}

export function algoliaResponseArrayAdapter(
  response: MultipleQueriesResponse<AlgoliaGameFactoryModel>,
  currency: CurrencyType
): ITokenFactoriesByGame {
  const results = response.results[0] as SearchResponse<AlgoliaGameFactoryModel>;
  const nodes = algoliaResponseAdapter(results.hits, currency);
  return {
    nodes,
    totalCount: response.results[0]['nbHits'],
    pagination: {
      skip: results.offset,
      limit: results.length,
    },
  };
}

export function adaptPrices(prices: AlgoliaGameFactoryModel['prices'], currency: CurrencyType): ITokenPrice[] {
  const price = prices.find((price) => price.currency === currency);

  if (!price) {
    return [];
  }

  return [
    {
      ...price,
      discount: price.discountBasisPoint,
      discountEndDate: price.discountEndDate && new Date(price.discountEndDate).toISOString(),
    },
  ];
}

export interface AlgoliaGameFactoryModel {
  gameId: string;
  id: string;
  onChainId: number;
  name: string;
  description: string;
  contentType: {
    id: string;
    code: string;
    icon: string;
    name: string;
  };
  image: {
    images: Array<{
      width: number;
      height: number;
      url: string;
    }>;
  };
  languages: {
    audio: Array<string>;
    subtitles: Array<string>;
    interfaces: Array<string>;
  };
  multimedia: {
    backGroundImage: {
      images: Array<{
        width: number;
        height: number;
        url: string;
      }>;
    };
    imageGalleryList: Array<{
      images: Array<{
        width: number;
        height: number;
        url: string;
      }>;
    }>;
  };
  freeToPlay: boolean;
  prices: Array<{
    currency: string;
    symbol: string;
    originalAmount: number;
    amount: number;
    discountBasisPoint: number;
    discountEndDate: string;
  }>;
  objectID: string;
}
