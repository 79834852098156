import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import { AlgoliaSearchService } from '../../algolia/algolia-search.service';
import { CurrencyService } from '../../algolia/currency.service';
import {
  Game,
  GameDetail,
  GameList,
  IPaginationFilters,
  IUserGamesWishlistId,
  OrderBy,
  Pagination,
} from '../../models';
import { IGameStoreStrategy } from '../../models/game/interfaces/game-store-strategy.interface';
import { GameStoreHttpService } from '../../services/game/game-store-http.service';

import { AlgoliaGame, filtersAdapter, gameListAdapter, gamesAdapter } from './game-algolia.adapter';

export enum GameIndexName {
  GAMES = 'games',
  GAME_TITLE_ASC = 'games_title_asc',
  GAMES_BY_RELEVANCE = 'games_lastUpdateTimestamp_desc',
  GAME_RELEASE_DATE_DESC = 'games_releaseDateTimestamp_desc',
}

const gameIndexMap: { [key in OrderBy]: GameIndexName } = {
  [OrderBy.RELEVANCE]: GameIndexName.GAMES_BY_RELEVANCE,
  [OrderBy.NAME]: GameIndexName.GAME_TITLE_ASC,
  [OrderBy.RELEASE_DATE]: GameIndexName.GAME_RELEASE_DATE_DESC,
};

@Injectable({
  providedIn: 'root',
})
export class GameAlgoliaService implements IGameStoreStrategy {
  constructor(
    private algoliaSearchService: AlgoliaSearchService,
    private currencyService: CurrencyService,
    private gameStoreHttpService: GameStoreHttpService,
  ) {}

  /**
   * Get games
   * @param search
   * @param filters - https://www.algolia.com/doc/api-reference/api-parameters/filters/
   * @param pagination
   * @param attributesToRetrieve - https://www.algolia.com/doc/api-reference/api-parameters/attributesToRetrieve/
   * @param facets - https://www.algolia.com/doc/api-reference/api-parameters/facets/
   * @param indexName
   */
  games(
    filters: string,
    pagination: { offset: number; length: number },
    attributesToRetrieve?: string[],
    search?: string,
    facets?: string[],
    indexName = GameIndexName.GAMES_BY_RELEVANCE,
  ): Observable<GameList> {
    return this.algoliaSearchService
      .search<AlgoliaGame>({
        indexName,
        filters,
        search,
        offset: pagination.offset,
        length: pagination.length,
        attributesToRetrieve,
        facets,
      })
      .pipe(
        switchMap((response) =>
          this.currencyService.currency$.pipe(map((currency) => gameListAdapter(response, currency))),
        ),
      );
  }

  /**
   * Get games by specific IDs
   * @param ids - Game IDs
   * @param attributesToRetrieve - https://www.algolia.com/doc/api-reference/api-parameters/attributesToRetrieve/
   * @param indexName
   */
  gamesByIds(
    ids: string[],
    attributesToRetrieve?: string[],
    indexName = GameIndexName.GAMES_BY_RELEVANCE,
  ): Observable<GameDetail[]> {
    return this.algoliaSearchService
      .search({
        indexName,
        filters: ids.map((id) => `objectID:${id}`).join(' OR '),
        length: ids.length,
        attributesToRetrieve,
      })
      .pipe(
        switchMap((response) => {
          return this.currencyService.currency$.pipe(map((currency) => gamesAdapter(response, currency)));
        }),
        map((games) =>
          // sort result by ids array
          ids.reduce((result, id) => {
            const gameFound = games.find((game) => game.id === id);
            if (gameFound) result.push(gameFound);
            return result;
          }, []),
        ),
      );
  }

  getAllGames(filters?: IPaginationFilters): Observable<GameList> {
    const pagination = filters?.pagination;
    const filter = filters?.filter;
    const indexName = this.getIndexName(filter?.orderBy);
    const algoliaFilter = filtersAdapter(filter);
    const search = filter?.search;
    const facets = filter?.withFiltersCount
      ? ['hasActiveDiscount', 'categories', '_tags', 'features', 'playingModes']
      : [];
    const attributesToRetrieve = [
      'objectID',
      'title',
      'editorName',
      'categories',
      'multimedia.imageGalleryList.images',
      'buyableGameFactory',
      'hasFreeToPlay',
    ];

    return this.games(
      algoliaFilter,
      { offset: pagination?.skip, length: pagination?.limit },
      attributesToRetrieve,
      search,
      facets,
      indexName,
    );
  }

  getRecommendedGames(): Observable<GameDetail[]> {
    const attributesToRetrieve = [
      'objectID',
      'title',
      'editorName',
      'description',
      'descriptionShort',
      'multimedia.imageGalleryList.images',
      'multimedia.largeHeroImage.images',
      'multimedia.videosPreviews',
      'systemsRequirements.osName',
      'buyableGameFactory',
      'hasFreeToPlay',
    ];
    return this.gameStoreHttpService
      .getRecommendedGameIds()
      .pipe(switchMap((ids) => this.gamesByIds(ids, attributesToRetrieve)));
  }

  getSuggestedGames(): Observable<Game[]> {
    const attributesToRetrieve = [
      'objectID',
      'title',
      'editorName',
      'multimedia.imageGalleryList.images',
      'buyableGameFactory',
      'hasFreeToPlay',
    ];
    return this.gameStoreHttpService
      .getSuggestedGameIds()
      .pipe(switchMap((ids) => this.gamesByIds(ids, attributesToRetrieve)));
  }

  getSpecialOffers(pagination: Pagination): Observable<Game[]> {
    const attributesToRetrieve = [
      'objectID',
      'title',
      'editorName',
      'multimedia.imageGalleryList.images',
      'buyableGameFactory',
      'hasFreeToPlay',
    ];
    return this.games(
      'hasActiveDiscount:true',
      { offset: pagination?.skip, length: pagination?.limit },
      attributesToRetrieve,
    ).pipe(
      map((gameList) => gameList.nodes),
      map((games) => games.filter((game) => game.getTokenPrices()?.discount > 1)),
    );
  }

  getGameDetailById$(id: string): Observable<GameDetail | { error: string }> {
    const attributesToRetrieve = [
      'objectID',
      'title',
      'description',
      'descriptionShort',
      'editorName',
      'developerName',
      'publisherName',
      'categories',
      'releaseDate',
      'systemsRequirements',
      'rating',
      '_tags',
      'playingModes',
      'features',
      'multimedia.imageGalleryList.images',
      'multimedia.largeHeroImage.images',
      'multimedia.boxArtImage.images',
      'multimedia.videosPreviews',
      'ageRating',
      'links',
      'languages',
      'buyableGameFactory',
      'hasFreeToPlay',
      'hasActiveDiscount',
    ];
    return this.gamesByIds([id], attributesToRetrieve).pipe(map((games) => games[0] || { error: 'Not found' }));
  }

  getRelatedGames(category: string, pagination: Pagination): Observable<Game[]> {
    const attributesToRetrieve = [
      'objectID',
      'title',
      'editorName',
      'multimedia.imageGalleryList.images',
      'buyableGameFactory',
      'hasFreeToPlay',
    ];
    return this.games(
      `categories:'${category}'`,
      { offset: pagination?.skip, length: pagination?.limit },
      attributesToRetrieve,
    ).pipe(map((gameList) => gameList.nodes));
  }

  getUserWishList(): Observable<Game[]> {
    return of([]);
  }

  getUserWishlistGameIds(): Observable<IUserGamesWishlistId[]> {
    return of([]);
  }

  addGameToWishList$(): Observable<Game[]> {
    return of([]);
  }

  removeGameFromWishList$(): Observable<Game[]> {
    return of([]);
  }

  private getIndexName(orderBy: string = OrderBy.RELEVANCE): GameIndexName {
    return gameIndexMap[orderBy] ?? GameIndexName.GAMES_BY_RELEVANCE;
  }
}
