<div class="token-geofencing-title">
  <span class="f-size-10 text-uppercase text-white-alpha-7" i18n>Geofencing</span>
  <i
    container="ultra-id-card-geofencing"
    ngbTooltip="Geofencing indicates the countries that have access to a specific Uniq."
    class="d-inline-block bg-white-alpha-7 icon-info"></i>
</div>

<div class="p-2">
  <div class="user-availability {{ currentUserCountry?.available ? 'available' : 'not-available' }}">
    <div class="geofencing-icon-wrap">
      <i class="icon {{ currentUserCountry?.available ? 'icon-world' : 'icon-world-na' }}"></i>
    </div>

    <div class="f-size-12 d-flex align-items-center ms-2" ultraAutotestAttribute="token-geofencing-message">
      @if (currentUserCountry?.available) {
        <ng-container i18n> You can purchase and trade this Uniq in your country. </ng-container>
      }

      @if (!currentUserCountry?.available) {
        <ng-container i18n> You cannot purchase and trade this Uniq in your country. </ng-container>
      }

      <div class="country ms-2 {{ currentUserCountry?.available ? 'available' : 'not-available' }}">
        <i class="country__flag iti__flag d-inline-block iti__{{ currentUserCountry?.alpha2Code?.toLowerCase() }}"></i>
        <span class="ms-2">{{ currentUserCountry?.name }}</span>
      </div>
    </div>
  </div>

  <div class="geofencing-filters">
    <div i18n class="f-size-16 fw-bold text-white">Worldwide geofencing rules</div>

    @if (isSearchActive) {
      <div class="search-input">
        <ultra-input
          cssClass="px-4"
          [control]="searchControl"
          icon="search"
          dataId="token-geofencing-search-input"></ultra-input>
        <i class="icon icon-cross-sm" (click)="toggleSearch()"></i>
      </div>
    }

    @if (!isSearchActive) {
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <div
            ultraOverlayHost
            ultraClickHostListener
            class="region-filter cursor-pointer"
            panelClass="region-filter-panel"
            scrollStrategy="noop"
            ultraAutotestAttribute="geofencing-region-filter"
            [template]="filterList"
            [positions]="{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: -32 }">
            <i class="icon icon-world"></i>
            <div class="text-white-alpha-7 f-size-10 ms-2">{{ activeRegion?.name }}</div>
            <i class="icon icon-chevron-sm ms-auto"></i>
          </div>
        </div>
        <ultra-radio-group class="d-flex align-items-center mx-2" [formControl]="availabilityFilter">
          <div
            class="global-counter f-size-10 me-1"
            [class.available]="availabilityFilter.value === 'available'"
            ultraRadioItem
            ultraAutotestAttribute="token-geofencing-available-filter"
            value="available">
            <i class="icon icon-world"></i>
            <div class="ms-2">{{ activeRegion?.available }}</div>
          </div>
          <div
            class="global-counter f-size-10 ms-1"
            [class.not-available]="availabilityFilter.value === 'not_available'"
            ultraRadioItem
            ultraAutotestAttribute="token-geofencing-not-available-filter"
            value="not_available">
            <i class="icon icon-world-na"></i>
            <div class="ms-2">{{ activeRegion?.not_available }}</div>
          </div>
        </ultra-radio-group>
        <button
          type="button"
          class="search-icon"
          (click)="toggleSearch()"
          ultraAutotestAttribute="token-geofencing-search-button">
          <i class="icon icon-search"></i>
        </button>
      </div>
    }
  </div>

  <div class="countries-list {{ displayList?.length === 0 ? 'justify-content-center align-content-center' : '' }}">
    @for (country of displayList; track country) {
      <div
        class="country {{ country.available ? 'available' : 'not-available' }}"
        ultraAutotestAttribute="token-geofencing-country">
        <i class="country__flag iti__flag d-inline-block iti__{{ country.alpha2Code?.toLowerCase() }}"></i>
        <div class="ms-2 f-size-10">{{ country.name }}</div>
      </div>
    }

    @if (displayList.length === 0) {
      <div class="no-result">
        <div class="no-result-icon-wrap">
          <i class="icon icon-search-20"></i>
        </div>
        <span class="text-white-alpha-7 f-size-12">No results</span>
      </div>
    }
  </div>
</div>

<ng-template #filterList let-overlayRef="overlayRef">
  <div ultraScroll theme="dark" class="region-scrollbar">
    @for (region of regions; track region) {
      <div
        [ultraAutotestAttribute]="'geofencing-region-' + region.name.toLowerCase()"
        class="region-item cursor-pointer"
        (click)="regionChange(region, overlayRef)">
        <i class="icon icon-world"></i>
        <div class="ms-2 f-size-10">{{ region.name }}</div>
        <div class="ms-auto region-counter f-size-8">
          <div class="region-count available">{{ region.available }}</div>
          <div class="region-count not-available">{{ region.not_available }}</div>
        </div>
      </div>
    }
  </div>
</ng-template>
