import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthService } from '@ultra/core/auth';

import { IGeofencingData } from '../../../models/geofencing/interfaces/geofencing-data.interface';
import { IGeofencingDisplayEntity } from '../../../models/geofencing/interfaces/geofencing-display-entity.intefrace';
import {
  IGeofencingRegionDisplay,
  IGeofencingRegionsMap,
} from '../../../models/geofencing/interfaces/geofencing-regions-map.interface';

@UntilDestroy()
@Component({
  selector: 'ultra-id-card-geofencing',
  templateUrl: './id-card-geofencing.component.html',
  styleUrls: ['./id-card-geofencing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardGeofencingComponent implements OnInit {
  @Input() geofencing: string[];
  @Input() geofencingData: IGeofencingData;
  @Output() currentUserCountryOutput = new EventEmitter<IGeofencingDisplayEntity>();

  currentUserCountry: IGeofencingDisplayEntity;
  displayList: IGeofencingDisplayEntity[] = [];
  activeRegion: IGeofencingRegionDisplay;
  regions: IGeofencingRegionDisplay[] = [];
  isSearchActive = false;
  searchControl = new UntypedFormControl();
  availabilityFilter = new UntypedFormControl(null);

  private regionsMap: IGeofencingRegionsMap;
  private countries: IGeofencingDisplayEntity[];

  private cdr = inject(ChangeDetectorRef);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.registerFormListeners();

    const tokenGeofencingMap = this.geofencing.reduce((acc, geofencing) => ({ ...acc, [geofencing]: true }), {});

    this.regionsMap = this.geofencingData.regions
      .filter((region) => !!region.parentRegionId)
      .reduce(
        (acc, region) => ({ ...acc, [region.id]: { ...region, countries: [], available: 0, not_available: 0 } }),
        {},
      );

    this.countries = this.geofencingData.countries.map((country) => {
      const available = !!tokenGeofencingMap[country.id];
      const region = this.regionsMap[country.parentRegionId];
      const displayCountry = { ...country, available };
      if (region) {
        region.countries.push(displayCountry);
        if (available) {
          region.available++;
        }
      }
      return displayCountry;
    });

    this.regions = Object.keys(this.regionsMap).map((key) => {
      const region = this.regionsMap[key];
      region.not_available = region.countries.length - region.available;
      return region;
    });

    this.regions.unshift({
      name: 'World',
      countries: this.countries,
      available: this.geofencing.length,
      not_available: this.countries.length - this.geofencing.length,
    } as IGeofencingRegionDisplay);

    this.getCurrentUserCountry();
    this.regionChange(this.regions[0]);
  }

  toggleSearch(): void {
    this.isSearchActive = !this.isSearchActive;
    if (this.searchControl.value) {
      this.searchControl.reset(null, { emitEvent: false });
      this.availabilityFilter.updateValueAndValidity();
    }
  }

  regionChange(region: IGeofencingRegionDisplay, overlayRef = null): void {
    this.activeRegion = region;
    this.availabilityFilter.updateValueAndValidity();
    overlayRef?.detach();
  }

  private getCurrentUserCountry(): void {
    const jwtHelper = new JwtHelperService();
    this.authService.accessToken$.subscribe((accessToken: string) => {
      const userCountryCode = jwtHelper.decodeToken(accessToken).countryCode;
      this.currentUserCountry = this.countries.find((item) => item.alpha2Code === userCountryCode);
      this.currentUserCountryOutput.emit(this.currentUserCountry);
      this.cdr.markForCheck();
    });
  }

  private registerFormListeners(): void {
    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (!value) {
        this.displayList = this.activeRegion.countries;
      } else {
        this.displayList = this.countries.filter((country) => country.name.toLowerCase().includes(value.toLowerCase()));
      }
      this.cdr.markForCheck();
    });

    this.availabilityFilter.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === null) {
        this.displayList = this.activeRegion.countries;
      } else {
        const expectedValue = value === 'available';
        this.displayList = this.activeRegion.countries.filter((country) => country.available === expectedValue);
      }
      this.cdr.markForCheck();
    });
  }
}
