import { gql } from 'apollo-angular';

import {
  GDC_IMAGE_WITH_ORDER_GQL,
  IMAGE_DETAIL_GQL,
  PRICES,
  TOKEN_FACTORY_PRICE,
} from '../../gql/shared/queries/shared.query';

export interface GameFactoryFilterInput {
  ids: string[];
  publisherId: string;
  onChainIds: string[];
}

export const FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY = gql`
  query findPublishedTokenFactoryById($id: ID!) {
    findPublishedTokenFactoryById(id: $id) {
      id
      blockchainId
      onChainId
      publisherId
      name
      description
      factoryType
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
      content {
        gameContents {
          gameId
          publisherId
          builds {
            buildId
            branches
            os
            type
          }
          components {
            repositoryId
            branches
            os
            type
          }
        }
      }
      prices ${PRICES}
      livePrices ${TOKEN_FACTORY_PRICE}
      geofencing
      multimedia {
        backGroundImage {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
        }
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
      languages {
        audio
        subtitles
        interfaces
      }
      freeToPlay
    }
  }
`;

export const GAME_PUBLISHED_GAME_FACTORIES = gql`
  query gamePublishedGameFactories($filter: GameFactoryFilterInput!, $pagination: PaginationInput) {
    gamePublishedGameFactories(filter: $filter, pagination: $pagination) {
      data {
        content {
          gameContents {
            gameId
          }
        }
        id
        onChainId
      }
      totalCount
    }
  }
`;
