import { IAuthTokenUser, IUser } from '../../models/user/interfaces/user.interface';

export class SetUser {
  static readonly type = '[User] Set User';

  constructor(public payload: IUser) {}
}

export class SetTokenUserData {
  static readonly type = '[User] Set Keycloak User Data';
  constructor(public payload: IAuthTokenUser) {}
}

export class UpdateUserKeysStatus {
  static readonly type = '[User] Update User Keys Status';

  constructor(public payload: boolean) {}
}

export class UpdateBlockchainStatus {
  static readonly type = '[User] Update Blockchain Account Status';

  constructor(public payload: { blockchainId; blockchainStatus; deviceSyncStatus }) {}
}

export class SetUserInitialState {
  static readonly type = '[User] Set User Initial state';
}
