import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slug',
  standalone: true,
})
export class SlugPipe implements PipeTransform {
  transform(value: any): any {
    const replacement = '_';
    const transformed = value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, ' ')
      .trim()
      .replace(/\s+/g, replacement);
    return transformed || replacement;
  }
}
