export default {
  data: {
    countries: [
      {
        id: '5e4e8bcab00a6d0022ef2031',
        name: 'Afghanistan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'AF',
      },
      {
        id: '5e4e8bcab00a6d0022ef2065',
        name: 'Albania',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'AL',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb4',
        name: 'Algeria',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'DZ',
      },
      {
        id: '616825d910e787e2fbe3f753',
        name: 'American Samoa',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'AS',
      },
      {
        id: '5e4e8bcab00a6d0022ef2066',
        name: 'Andorra',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'AD',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb5',
        name: 'Angola',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'AO',
      },
      {
        id: '616825d910e7873c79e3f752',
        name: 'Anguilla',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'AI',
      },
      {
        id: '5e4e8bcab00a6d0022ef2046',
        name: 'Antarctica',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'AQ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2002',
        name: 'Antigua and Barbuda',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'AG',
      },
      {
        id: '5e4e8bcab00a6d0022ef2059',
        name: 'Argentina',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'AR',
      },
      {
        id: '5e4e8bcab00a6d0022ef2067',
        name: 'Armenia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'AM',
      },
      {
        id: '616825d910e78733c5e3f754',
        name: 'Aruba',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'AW',
      },
      {
        id: '616825d910e787944be3f751',
        name: 'Ascension Island',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'AC',
      },
      {
        id: '5e4e8bcab00a6d0022ef204b',
        name: 'Australia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'AU',
      },
      {
        id: '5e4e8bcab00a6d0022ef2016',
        name: 'Austria',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'AT',
      },
      {
        id: '5e4e8bcab00a6d0022ef2068',
        name: 'Azerbaijan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'AZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2043',
        name: 'Bahamas',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
        alpha2Code: 'BS',
      },
      {
        id: '5e4e8bcab00a6d0022ef2032',
        name: 'Bahrain',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'BH',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fea',
        name: 'Bangladesh',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'BD',
      },
      {
        id: '5e4e8bcab00a6d0022ef2003',
        name: 'Barbados',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'BB',
      },
      {
        id: '5e4e8bcab00a6d0022ef2069',
        name: 'Belarus',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'BY',
      },
      {
        id: '5e4e8bcab00a6d0022ef2017',
        name: 'Belgium',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'BE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2004',
        name: 'Belize',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'BZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb6',
        name: 'Benin',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'BJ',
      },
      {
        id: '616825d910e787ae52e3f757',
        name: 'Bermuda',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'BM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1feb',
        name: 'Bhutan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'BT',
      },
      {
        id: '5e4e8bcab00a6d0022ef205a',
        name: 'Bolivia (Plurinational State of)',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'BO',
      },
      {
        id: '616825d910e787b52ae3f758',
        name: 'Bonaire Sint Eustatius and Saba',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'BQ',
      },
      {
        id: '5e4e8bcab00a6d0022ef206a',
        name: 'Bosnia and Herzegovina',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'BA',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb7',
        name: 'Botswana',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'BW',
      },
      {
        id: '5e4e8bcab00a6d0022ef205b',
        name: 'Brazil',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'BR',
      },
      {
        id: '616825d910e7876d20e3f77c',
        name: 'British Indian Ocean Territory',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'IO',
      },
      {
        id: '616825d910e7874227e3f778',
        name: 'British Virgin Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'VG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fec',
        name: 'Brunei Darussalam',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'BN',
      },
      {
        id: '5e4e8bcab00a6d0022ef2018',
        name: 'Bulgaria',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'BG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb8',
        name: 'Burkina Faso',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'BF',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fb9',
        name: 'Burundi',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'BI',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fba',
        name: 'Cabo Verde',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CV',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fed',
        name: 'Cambodia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'KH',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fbb',
        name: 'Cameroon',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CM',
      },
      {
        id: '5e4e8bcab00a6d0022ef2044',
        name: 'Canada',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
        alpha2Code: 'CA',
      },
      {
        id: '616825d910e787f187e3f768',
        name: 'Cayman Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'KY',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fbc',
        name: 'Central African Republic',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CF',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fbd',
        name: 'Chad',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'TD',
      },
      {
        id: '5e4e8bcab00a6d0022ef205c',
        name: 'Chile',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'CL',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fee',
        name: 'China',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'CN',
      },
      {
        id: '616825d910e787bc65e3f75c',
        name: 'Christmas Island',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'CX',
      },
      {
        id: '616825d910e787a5b0e3f759',
        name: 'Cocos Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'CC',
      },
      {
        id: '5e4e8bcab00a6d0022ef205d',
        name: 'Colombia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'CO',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fbe',
        name: 'Comoros',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'KM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fbf',
        name: 'Congo',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc0',
        name: 'Congo (Democratic Republic of the)',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CD',
      },
      {
        id: '616825d910e7871f5ce3f75a',
        name: 'Cook Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'CK',
      },
      {
        id: '5e4e8bcab00a6d0022ef2005',
        name: 'Costa Rica',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'CR',
      },
      {
        id: '5e4e8bcab00a6d0022ef2019',
        name: 'Croatia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'HR',
      },
      {
        id: '5e4e8bcab00a6d0022ef2006',
        name: 'Cuba',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'CU',
      },
      {
        id: '616825d910e787dfa5e3f75b',
        name: 'Curacao',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'CW',
      },
      {
        id: '5e4e8bcab00a6d0022ef201a',
        name: 'Cyprus',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'CY',
      },
      {
        id: '5e4e8bcab00a6d0022ef201b',
        name: 'Czech Republic',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'CZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef201c',
        name: 'Denmark',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'DK',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc2',
        name: 'Djibouti',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'DJ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2007',
        name: 'Dominica',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'DM',
      },
      {
        id: '5e4e8bcab00a6d0022ef2008',
        name: 'Dominican Republic',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'DO',
      },
      {
        id: '5e4e8bcab00a6d0022ef205e',
        name: 'Ecuador',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'EC',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc3',
        name: 'Egypt',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'EG',
      },
      {
        id: '5e4e8bcab00a6d0022ef2009',
        name: 'El Salvador',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'SV',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc4',
        name: 'Equatorial Guinea',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GQ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc5',
        name: 'Eritrea',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ER',
      },
      {
        id: '5e4e8bcab00a6d0022ef201d',
        name: 'Estonia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'EE',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc6',
        name: 'Ethiopia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ET',
      },
      {
        id: '616825d910e7872730e3f75d',
        name: 'Falkland Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'FK',
      },
      {
        id: '616825d910e7873be9e3f75e',
        name: 'Faroe Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'FO',
      },
      {
        id: '5e4e8bcab00a6d0022ef204c',
        name: 'Fiji',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'FJ',
      },
      {
        id: '5e4e8bcab00a6d0022ef201e',
        name: 'Finland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'FI',
      },
      {
        id: '5e4e8bcab00a6d0022ef201f',
        name: 'France',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'FR',
      },
      {
        id: '616825d910e787bc37e3f75f',
        name: 'French Guiana',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'GF',
      },
      {
        id: '616825d910e7871ce4e3f771',
        name: 'French Polynesia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'PF',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc7',
        name: 'Gabon',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GA',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc8',
        name: 'Gambia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GM',
      },
      {
        id: '5e4e8bcab00a6d0022ef206b',
        name: 'Georgia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'GE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2020',
        name: 'Germany',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'DE',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc9',
        name: 'Ghana',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GH',
      },
      {
        id: '616825d910e78710e7e3f761',
        name: 'Gibraltar',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'GI',
      },
      {
        id: '5e4e8bcab00a6d0022ef2021',
        name: 'Greece',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'GR',
      },
      {
        id: '616825d910e7870d44e3f762',
        name: 'Greenland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'GL',
      },
      {
        id: '5e4e8bcab00a6d0022ef200a',
        name: 'Grenada',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'GD',
      },
      {
        id: '616825d910e78711b3e3f763',
        name: 'Guadeloupe',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'GP',
      },
      {
        id: '616825d910e7876145e3f764',
        name: 'Guam',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'GU',
      },
      {
        id: '5e4e8bcab00a6d0022ef200b',
        name: 'Guatemala',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'GT',
      },
      {
        id: '616825d910e78708d9e3f760',
        name: 'Guernsey',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'GG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fca',
        name: 'Guinea',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GN',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fcb',
        name: 'Guinea-Bissau',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'GW',
      },
      {
        id: '5e4e8bcab00a6d0022ef205f',
        name: 'Guyana',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'GY',
      },
      {
        id: '5e4e8bcab00a6d0022ef200c',
        name: 'Haiti',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'HT',
      },
      {
        id: '5e4e8bcab00a6d0022ef200d',
        name: 'Honduras',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'HN',
      },
      {
        id: '616825d910e7876485e3f765',
        name: 'Hong Kong',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'HK',
      },
      {
        id: '5e4e8bcab00a6d0022ef2022',
        name: 'Hungary',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'HU',
      },
      {
        id: '5e4e8bcab00a6d0022ef206c',
        name: 'Iceland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'IS',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fef',
        name: 'India',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'IN',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff0',
        name: 'Indonesia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'ID',
      },
      {
        id: '5e4e8bcab00a6d0022ef2033',
        name: 'Iran',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'IR',
      },
      {
        id: '5e4e8bcab00a6d0022ef2034',
        name: 'Iraq',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'IQ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2023',
        name: 'Ireland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'IE',
      },
      {
        id: '616825d910e7874035e3f766',
        name: 'Isle of Man',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'IM',
      },
      {
        id: '5e4e8bcab00a6d0022ef2035',
        name: 'Israel',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'IL',
      },
      {
        id: '5e4e8bcab00a6d0022ef2024',
        name: 'Italy',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'IT',
      },
      {
        id: '5e4e8bcab00a6d0022ef200e',
        name: 'Jamaica',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'JM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff1',
        name: 'Japan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'JP',
      },
      {
        id: '616825d910e787135de3f767',
        name: 'Jersey',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'JE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2036',
        name: 'Jordan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'JO',
      },
      {
        id: '5e4e8bcab00a6d0022ef206d',
        name: 'Kazakhstan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'KZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fcc',
        name: 'Kenya',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'KE',
      },
      {
        id: '5e4e8bcab00a6d0022ef204d',
        name: 'Kiribati',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'KI',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff2',
        name: 'Korea (Democratic People’s Republic of) north',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'KP',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff3',
        name: 'Korea (Republic of) south',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'KR',
      },
      {
        id: '616825d910e787342ee3f77a',
        name: 'Kosovo',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'XK',
      },
      {
        id: '5e4e8bcab00a6d0022ef2037',
        name: 'Kuwait',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'KW',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff4',
        name: 'Kyrgyzstan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'KG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff5',
        name: 'Laos',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'LA',
      },
      {
        id: '5e4e8bcab00a6d0022ef2025',
        name: 'Latvia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'LV',
      },
      {
        id: '5e4e8bcab00a6d0022ef2038',
        name: 'Lebanon',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'LB',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fcd',
        name: 'Lesotho',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'LS',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fce',
        name: 'Liberia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'LR',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fcf',
        name: 'Libya',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'LY',
      },
      {
        id: '5e4e8bcab00a6d0022ef206e',
        name: 'Liechtenstein',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'LI',
      },
      {
        id: '5e4e8bcab00a6d0022ef2026',
        name: 'Lithuania',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'LT',
      },
      {
        id: '5e4e8bcab00a6d0022ef2027',
        name: 'Luxembourg',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'LU',
      },
      {
        id: '616825d910e787750ee3f76a',
        name: 'Macau',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'MO',
      },
      {
        id: '5e4e8bcab00a6d0022ef206f',
        name: 'Macedonia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'MK',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd0',
        name: 'Madagascar',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MG',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd1',
        name: 'Malawi',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MW',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff6',
        name: 'Malaysia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'MY',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff7',
        name: 'Maldives',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'MV',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd2',
        name: 'Mali',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ML',
      },
      {
        id: '5e4e8bcab00a6d0022ef2028',
        name: 'Malta',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'MT',
      },
      {
        id: '5e4e8bcab00a6d0022ef204e',
        name: 'Marshall Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'MH',
      },
      {
        id: '616825d910e787ce10e3f76c',
        name: 'Martinique',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'MQ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd3',
        name: 'Mauritania',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MR',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd4',
        name: 'Mauritius',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MU',
      },
      {
        id: '616825d910e7870116e3f77b',
        name: 'Mayotte',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'YT',
      },
      {
        id: '5e4e8bcab00a6d0022ef200f',
        name: 'Mexico',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'MX',
      },
      {
        id: '5e4e8bcab00a6d0022ef204f',
        name: 'Micronesia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'FM',
      },
      {
        id: '5e4e8bcab00a6d0022ef2070',
        name: 'Moldova',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'MD',
      },
      {
        id: '5e4e8bcab00a6d0022ef2071',
        name: 'Monaco',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'MC',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff8',
        name: 'Mongolia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'MN',
      },
      {
        id: '5e4e8bcab00a6d0022ef2072',
        name: 'Montenegro',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'ME',
      },
      {
        id: '616825d910e787eebee3f76d',
        name: 'Montserrat',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'MS',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd5',
        name: 'Morocco',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MA',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd6',
        name: 'Mozambique',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'MZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ff9',
        name: 'Myanmar',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'MM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd7',
        name: 'Namibia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'NA',
      },
      {
        id: '5e4e8bcab00a6d0022ef2050',
        name: 'Nauru',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'NR',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ffa',
        name: 'Nepal',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'NP',
      },
      {
        id: '5e4e8bcab00a6d0022ef2029',
        name: 'Netherlands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'NL',
      },
      {
        id: '616825d910e78750e5e3f83d',
        name: 'Netherlands Antilles',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'AN',
      },
      {
        id: '616825d910e787b9d3e3f76e',
        name: 'New Caledonia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'NC',
      },
      {
        id: '5e4e8bcab00a6d0022ef2051',
        name: 'New Zealand',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'NZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2010',
        name: 'Nicaragua',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'NI',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd8',
        name: 'Niger',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'NE',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fd9',
        name: 'Nigeria',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'NG',
      },
      {
        id: '616825d910e7870a20e3f770',
        name: 'Niue',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'NU',
      },
      {
        id: '616825d910e787c8bce3f76f',
        name: 'Norfolk Island',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'NF',
      },
      {
        id: '616825d910e7878dd9e3f76b',
        name: 'Northern Mariana Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'MP',
      },
      {
        id: '5e4e8bcab00a6d0022ef2073',
        name: 'Norway',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'NO',
      },
      {
        id: '5e4e8bcab00a6d0022ef2039',
        name: 'Oman',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'OM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ffb',
        name: 'Pakistan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'PK',
      },
      {
        id: '5e4e8bcab00a6d0022ef2052',
        name: 'Palau',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'PW',
      },
      {
        id: '5e4e8bcab00a6d0022ef2049',
        name: 'Palestine',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'PS',
      },
      {
        id: '5e4e8bcab00a6d0022ef2011',
        name: 'Panama',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'PA',
      },
      {
        id: '5e4e8bcab00a6d0022ef2053',
        name: 'Papua New Guinea',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'PG',
      },
      {
        id: '5e4e8bcab00a6d0022ef2060',
        name: 'Paraguay',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'PY',
      },
      {
        id: '5e4e8bcab00a6d0022ef2061',
        name: 'Peru',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'PE',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ffc',
        name: 'Philippines',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'PH',
      },
      {
        id: '616825d910e7876b01e3f83e',
        name: 'Pitcairn',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'PN',
      },
      {
        id: '5e4e8bcab00a6d0022ef202a',
        name: 'Poland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'PL',
      },
      {
        id: '5e4e8bcab00a6d0022ef202b',
        name: 'Portugal',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'PT',
      },
      {
        id: '616825d910e78700cee3f773',
        name: 'Puerto Rico',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'PR',
      },
      {
        id: '5e4e8bcab00a6d0022ef203a',
        name: 'Qatar',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'QA',
      },
      {
        id: '616825d910e787d103e3f774',
        name: 'Reunion',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'RE',
      },
      {
        id: '5e4e8bcab00a6d0022ef202c',
        name: 'Romania',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'RO',
      },
      {
        id: '5e4e8bcab00a6d0022ef2074',
        name: 'Russia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'RU',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fda',
        name: 'Rwanda',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'RW',
      },
      {
        id: '616825d910e7875028e3f756',
        name: 'Saint Barthelemy',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'BL',
      },
      {
        id: '616825d910e7879eb8e3f77d',
        name: 'Saint Helena',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'SH',
      },
      {
        id: '5e4e8bcab00a6d0022ef2012',
        name: 'Saint Kitts And Nevis',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'KN',
      },
      {
        id: '5e4e8bcab00a6d0022ef2013',
        name: 'Saint Lucia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'LC',
      },
      {
        id: '616825d910e7879349e3f769',
        name: 'Saint Martin',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'MF',
      },
      {
        id: '616825d910e7874dcce3f772',
        name: 'Saint Pierre and Miquelon',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'PM',
      },
      {
        id: '5e4e8bcab00a6d0022ef2014',
        name: 'Saint Vincent and The Grenadines',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'VC',
      },
      {
        id: '5e4e8bcab00a6d0022ef2054',
        name: 'Samoa',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'WS',
      },
      {
        id: '5e4e8bcab00a6d0022ef2075',
        name: 'San Marino',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'SM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fdb',
        name: 'Sao Tome and Principe',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ST',
      },
      {
        id: '5e4e8bcab00a6d0022ef203b',
        name: 'Saudi Arabia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'SA',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fdc',
        name: 'Senegal',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SN',
      },
      {
        id: '5e4e8bcab00a6d0022ef2076',
        name: 'Serbia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'RS',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fdd',
        name: 'Seychelles',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SC',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fde',
        name: 'Sierra Leone',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SL',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ffd',
        name: 'Singapore',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'SG',
      },
      {
        id: '616825d910e7877598e3f776',
        name: 'Sint Maarten',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'SX',
      },
      {
        id: '5e4e8bcab00a6d0022ef202d',
        name: 'Slovakia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'SK',
      },
      {
        id: '5e4e8bcab00a6d0022ef202e',
        name: 'Slovenia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'SI',
      },
      {
        id: '5e4e8bcab00a6d0022ef2055',
        name: 'Solomon Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'SB',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fdf',
        name: 'Somalia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SO',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe0',
        name: 'South Africa',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ZA',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe1',
        name: 'South Sudan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SS',
      },
      {
        id: '5e4e8bcab00a6d0022ef202f',
        name: 'Spain',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'ES',
      },
      {
        id: '5e4e8bcab00a6d0022ef1ffe',
        name: 'Sri Lanka',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'LK',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe2',
        name: 'Sudan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SD',
      },
      {
        id: '5e4e8bcab00a6d0022ef2062',
        name: 'Suriname',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'SR',
      },
      {
        id: '616825d910e787534be3f775',
        name: 'Svalbard and Jan Mayen',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'SJ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe3',
        name: 'Swaziland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'SZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2030',
        name: 'Sweden',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'SE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2077',
        name: 'Switzerland',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'CH',
      },
      {
        id: '5e4e8bcab00a6d0022ef203c',
        name: 'Syria',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'SY',
      },
      {
        id: '5e4e8bcab00a6d0022ef2048',
        name: 'Taiwan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'TW',
      },
      {
        id: '5e4e8bcab00a6d0022ef203d',
        name: 'Tajikistan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'TJ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe4',
        name: 'Tanzania',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'TZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fff',
        name: 'Thailand',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'TH',
      },
      {
        id: '5e4e8bcab00a6d0022ef2000',
        name: 'Timor-east',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'TL',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe5',
        name: 'Togo',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'TG',
      },
      {
        id: '616825d910e787666ce3f77e',
        name: 'Tokelau',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'TK',
      },
      {
        id: '5e4e8bcab00a6d0022ef2056',
        name: 'Tonga',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'TO',
      },
      {
        id: '5e4e8bcab00a6d0022ef2015',
        name: 'Trinidad and Tobago',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'TT',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe6',
        name: 'Tunisia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'TN',
      },
      {
        id: '5e4e8bcab00a6d0022ef203e',
        name: 'Turkey',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'TR',
      },
      {
        id: '5e4e8bcab00a6d0022ef203f',
        name: 'Turkmenistan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'TM',
      },
      {
        id: '616825d910e7874582e3f777',
        name: 'Turks',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'TC',
      },
      {
        id: '5e4e8bcab00a6d0022ef2057',
        name: 'Tuvalu',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'TV',
      },
      {
        id: '616825d910e78785f8e3f779',
        name: 'U.S. Virgin Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
        alpha2Code: 'VI',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe7',
        name: 'Uganda',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'UG',
      },
      {
        id: '5e4e8bcab00a6d0022ef2078',
        name: 'Ukraine',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'UA',
      },
      {
        id: '5e4e8bcab00a6d0022ef2040',
        name: 'United Arab Emirates',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'AE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2079',
        name: 'United Kingdom',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
        alpha2Code: 'GB',
      },
      {
        id: '5e4e8bcab00a6d0022ef2045',
        name: 'United States',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
        alpha2Code: 'US',
      },
      {
        id: '5e4e8bcab00a6d0022ef2063',
        name: 'Uruguay',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'UY',
      },
      {
        id: '5e4e8bcab00a6d0022ef2041',
        name: 'Uzbekistan',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'UZ',
      },
      {
        id: '5e4e8bcab00a6d0022ef2058',
        name: 'Vanuatu',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'VU',
      },
      {
        id: '5e4e8bcab00a6d0022ef204a',
        name: 'Vatican City',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'VA',
      },
      {
        id: '5e4e8bcab00a6d0022ef2064',
        name: 'Venezuela',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
        alpha2Code: 'VE',
      },
      {
        id: '5e4e8bcab00a6d0022ef2001',
        name: 'Viet Nam',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
        alpha2Code: 'VN',
      },
      {
        id: '616825d910e7874bf5e3f77f',
        name: 'Wallis and Futuna',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
        alpha2Code: 'WF',
      },
      {
        id: '5e4e8bcab00a6d0022ef2047',
        name: 'Western Sahara',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
        alpha2Code: 'EH',
      },
      {
        id: '5e4e8bcab00a6d0022ef2042',
        name: 'Yemen',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
        alpha2Code: 'YE',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe8',
        name: 'Zambia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ZM',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fe9',
        name: 'Zimbabwe',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'ZW',
      },
      {
        id: '5e4e8bcab00a6d0022ef1fc1',
        name: 'ivory coast',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
        alpha2Code: 'CI',
      },
      {
        id: '616825d910e787bcd6e3f755',
        name: 'Åland Islands',
        parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
        alpha2Code: 'AX',
      },
    ],
    regions: [
      {
        id: '5e4e8bc8b00a6d0022ef1f99',
        name: 'APAC',
        parentRegionId: null,
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f98',
        name: 'NCSA',
        parentRegionId: null,
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f9a',
        name: 'EMEA',
        parentRegionId: null,
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f9c',
        name: 'North America',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f9e',
        name: 'South America',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f9f',
        name: 'Pacific',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f99',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1f9d',
        name: 'Central America',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa1',
        name: 'Rest of Europe',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa0',
        name: 'Asia',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f99',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa3',
        name: 'Middle East',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa4',
        name: 'Africa',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa2',
        name: 'European Union',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
      },
      {
        id: '5e4e8bc8b00a6d0022ef1fa5',
        name: 'Other',
        parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
      },
    ],
  },
};
