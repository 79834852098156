import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService, ApolloClientName } from '@ultra/core/graphql';

import { Pagination } from '../..';
import { ApiStoreGqlConfig } from '../../graphql-client/api-store-gql.config';
import { IGameFactoryStrategy } from '../services/game-factory.service';

import {
  FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY,
  GAME_PUBLISHED_GAME_FACTORIES,
  GameFactoryFilterInput,
} from './game-factory.query';

@Injectable({
  providedIn: 'root',
})
export class GameFactoryGqlService implements IGameFactoryStrategy {
  private apiService = inject(ApiGraphQLService);

  findPublishedTokenFactoryById(id: string): Observable<any> {
    return this.apiService
      .getQuery(FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY, { id }, { cache: true })
      .pipe(map((result) => result.data.findPublishedTokenFactoryById));
  }

  publishedGameFactories(filter: Partial<GameFactoryFilterInput>, pagination: Pagination): Observable<any> {
    return this.apiService
      .getQuery(GAME_PUBLISHED_GAME_FACTORIES, { filter, pagination }, { cache: true })
      .pipe(map((result) => result.data.gamePublishedGameFactories));
  }

  publishedTokenFactoriesByGame(gameId?: string, pagination?: Pagination): Observable<any> {
    return this.apiService.getQuery(ApiStoreGqlConfig.PUBLISHED_TOKEN_FACTORIES_BY_GAME, { gameId, pagination }).pipe(
      map((result) => {
        return result.data.publishedTokenFactoriesByGame;
      }),
    );
  }

  tokenFactoriesByGame(gameId?: string, pagination?: Pagination): Observable<any> {
    return this.apiService
      .getQuery(
        ApiStoreGqlConfig.TOKEN_FACTORIES_BY_GAME,
        { gameId, pagination },
        { apolloClient: ApolloClientName.TOKEN_FACTORIES },
      )
      .pipe(
        map((result) => {
          return result.data.tokenFactoriesByGame;
        }),
      );
  }
}
