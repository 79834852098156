import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LanguageInterface } from '../../../interfaces';
import { IGeofencingData } from '../../../models/geofencing';
import countries from '../static/countries.data';
import languages from '../static/languages.data';

import { GeofencingServiceInterface } from './geofencing.service.interface';

@Injectable()
export class GeofencingJsonService implements GeofencingServiceInterface {
  getGeofencingData(): Observable<IGeofencingData> {
    return of(countries.data);
  }

  getLanguagesList(): Observable<LanguageInterface[]> {
    return of(languages.data.languages);
  }
}
