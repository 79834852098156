import { DataSource } from '@angular/cdk/collections';
import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { LanguageInterface } from '../../../interfaces/languages/languages.interface';
import { ITokenFGameComponent } from '../../../models/token-factory/interfaces/game-conponent.interface';
import { LanguagesService } from '../../../services/languages/languages.service';
import { IdCardEntity } from '../adapters/id-card.adapter';

import { IdCardContentDatasource } from './id-card-content.datasource';

@Component({
  selector: 'ultra-id-card-content',
  templateUrl: './id-card-content.component.html',
  styleUrls: ['./id-card-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdCardContentComponent implements OnChanges {
  @Input()
  idCardEntity: IdCardEntity;

  @Input()
  gameName: string;

  public dataSource: DataSource<any>;
  public columns = ['language', 'audio', 'subtitles', 'interfaces'];
  public languages$: Observable<LanguageInterface[]>;

  constructor(private languagesService: LanguagesService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idCardEntity) {
      this.languages$ = this.languagesService.langs$;
      if (!this.idCardEntity?.content?.languages) {
        return;
      }
      this.dataSource = new IdCardContentDatasource(this.prepareLanguagesData());
    }
  }

  public getBranch(branch: 'master' | 'beta'): boolean {
    const gameContent = this.idCardEntity.content.tokenContent.gameContents[0];

    return !!(
      gameContent.components.find((item: ITokenFGameComponent) => !!item.branches.includes(branch)) ||
      gameContent.builds.find((item) => item.branches.includes(branch))
    );
  }

  private prepareLanguagesData() {
    let langsMap = {};

    langsMap = this.convertLanguageByType('audio', langsMap);

    langsMap = this.convertLanguageByType('subtitles', langsMap);

    langsMap = this.convertLanguageByType('interfaces', langsMap);

    return Object.values(langsMap);
  }

  private convertLanguageByType(type: 'audio' | 'subtitles' | 'interfaces', result: any) {
    return this.idCardEntity.content.languages[type].reduce((acc, language) => {
      return { ...acc, [language]: { ...acc[language], language, [type]: true } };
    }, result);
  }

  get iconType(): string {
    switch (this.idCardEntity?.content?.contentTypeCode) {
      case 'game':
        return 'gamepad-sm';
      case 'dserver':
        return 'dserver-sm';
      case 'lp':
        return 'languages-sm';
      case 'demo':
        return 'demo-sm';
      case 'dlc':
        return 'dlc-sm';
    }
  }
}
