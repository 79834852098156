import { gql } from 'apollo-angular';

import { PAGINATION_GQL } from '@ultra/core/graphql';

import {
  GDC_IMAGE_GQL,
  GDC_IMAGE_WITH_ORDER_GQL,
  IMAGE_DETAIL_GQL,
  PRICES,
  TOKEN_FACTORY_CONTENT,
  TOKEN_FACTORY_PRICE,
} from '../../shared/queries/shared.query';

export const PUBLISHED_TOKEN_FACTORIES_BY_GAME_QUERY = gql`
  query publishedTokenFactoriesByGame($gameId: String!, $pagination: PaginationInput) {
    publishedTokenFactoriesByGame(gameId: $gameId, pagination: $pagination) {
      nodes {
        id
        blockchainId
        onChainId
        publisherId
        name
        prices ${PRICES}
        livePrices ${TOKEN_FACTORY_PRICE}
        image ${GDC_IMAGE_GQL}
        multimedia {
          backGroundImage ${GDC_IMAGE_GQL}
        }
        contentType ${TOKEN_FACTORY_CONTENT}
        freeToPlay,
        exclusiveAccess{
          uniqFactoryKeyIds,
          requiredAmount
         }
      }
    }
  }
`;

const TOKEN_FACTORY = `{
  onChainId,
  id
  blockchainId
  publisherId
  name
  description
  factoryType
  freeToPlay
  image {
    name
    fileExtension
    base ${IMAGE_DETAIL_GQL}
    images ${IMAGE_DETAIL_GQL}
  }
  contentType {
    id
    code
    index
    name
    icon
    shortDescription
    description
  }
  content {
    gameContents {
      gameId
      publisherId
      builds {
        repositoryId
        buildId
        branches
        os
        type
      }
      components {
        repositoryId
        branches
        os
        type
      }
    }
  }
  prices ${PRICES}
  livePrices {
    discount
  }
  revenueShare {
    blockchainId
    percentage
    stopEarning
  }
  status
  geofencing
  multimedia {
    backGroundImage {
      name
      fileExtension
      base ${IMAGE_DETAIL_GQL}
      images ${IMAGE_DETAIL_GQL}
    }
    imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
  }
  languages {
    audio
    subtitles
    interfaces
  }
  discounts {
    id
    discountBasisPoint
    startDate
    endDate
  }
  exclusiveAccess{
    uniqFactoryKeyIds
    requiredAmount
  }
}`;

export const TOKEN_FACTORIES_BY_GAME_QUERY = gql`
  query tokenFactoriesByGame($gameId: String!, $pagination: PaginationInput) {
    tokenFactoriesByGame(gameId: $gameId, pagination: $pagination) {
      nodes ${TOKEN_FACTORY}
      ${PAGINATION_GQL}
    }
  }
`;

export const TOKEN_FACTORY_BY_ID_QUERY = gql`
  query tokenFactoryById($id: ID!) {
    tokenFactoryById(id: $id) ${TOKEN_FACTORY}
  }
`;

export const FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY = gql`
  query findPublishedTokenFactoryById($id: ID!) {
    findPublishedTokenFactoryById(id: $id) {
      id
      blockchainId
      onChainId
      publisherId
      name
      description
      factoryType
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
      content {
        gameContents {
          gameId
          publisherId
          builds {
            buildId
            branches
            os
            type
          }
          components {
            repositoryId
            branches
            os
            type
          }
        }
      }
      prices ${PRICES}
      livePrices ${TOKEN_FACTORY_PRICE}
      geofencing
      multimedia {
        backGroundImage {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
        }
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
      languages {
        audio
        subtitles
        interfaces
      }
      freeToPlay
    }
  }
`;
