import {
  ADD_GAME_TO_WISHLIST,
  ALL_GAMES_QUERY,
  GAME_DETAIL_QUERY,
  GAMES_BY_IDS_QUERY,
  RELATED_GAMES_QUERY,
  REMOVE_GAME_FROM_WISHLIST,
  WISHLIST_GAMES_QUERY,
  WISHLIST_GAMES_QUERY_IDS,
} from '../gql/game';
import {
  FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY,
  PUBLISHED_TOKEN_FACTORIES_BY_GAME_QUERY,
  TOKEN_FACTORIES_BY_GAME_QUERY,
} from '../gql/token-factory';

export class ApiStoreGqlConfig {
  // ********** Queries **********

  static readonly ALL_GAMES = ALL_GAMES_QUERY;
  static readonly GAME_DETAIL = GAME_DETAIL_QUERY;
  static readonly GAMES_BY_IDS = GAMES_BY_IDS_QUERY;
  static readonly WISHLIST_GAMES = WISHLIST_GAMES_QUERY;
  static readonly WISHLIST_GAMES_IDS = WISHLIST_GAMES_QUERY_IDS;
  static readonly RELATED_GAMES = RELATED_GAMES_QUERY;
  static readonly TOKEN_FACTORIES_BY_GAME = TOKEN_FACTORIES_BY_GAME_QUERY;
  static readonly PUBLISHED_TOKEN_FACTORIES_BY_GAME = PUBLISHED_TOKEN_FACTORIES_BY_GAME_QUERY;
  static readonly FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID = FIND_PUBLISHED_TOKEN_FACTORIES_BY_ID_QUERY;

  // ********** Mutations **********
  static readonly ADD_GAME_TO_WISHLIST = ADD_GAME_TO_WISHLIST;
  static readonly REMOVE_GAME_FROM_WISHLIST = REMOVE_GAME_FROM_WISHLIST;
}
