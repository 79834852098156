import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { WINDOW } from '@ultra/core/providers';

import { gridSetup } from './responsive-layout.config';
import { GridPagination } from './responsive-layout.interfaces';

@UntilDestroy()
@Directive({
  selector: '[responsiveLayoutDirective]',
  standalone: true,
})
export class ResponsiveLayoutDirective implements OnInit, AfterViewInit {
  @Output() gridChange = new EventEmitter<any>();
  @Output() gridPaginationChange = new EventEmitter<GridPagination>();
  @Input() useElementWidth = false;
  @Input() noMargin = false;
  @Input() usePopupExpandedUnits = false;
  @Input() maxItemsPerPage: number;

  private resize$: BehaviorSubject<any> = new BehaviorSubject(this.window.innerWidth);

  private currentMatchName: string;

  @HostListener('window:resize', ['$event'])
  public onWindowResize() {
    const width = this.useElementWidth ? this.elementRef.nativeElement.offsetWidth : this.window.innerWidth;
    this.resize$.next(width);
  }

  constructor(
    private elementRef: ElementRef,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit() {
    this.resize$.pipe(debounceTime(100), untilDestroyed(this)).subscribe((screenSize) => {
      if (screenSize >= 1728) {
        screenSize = 1728;
      }
      const match = this.getGrid(screenSize);
      const grid = this.getWidth(match, screenSize);
      this.gridChange.next(grid);
      if (match.name !== this.currentMatchName) {
        this.currentMatchName = match.name;
        this.gridPaginationChange.next(grid.pagination);
      }
    });
  }

  ngAfterViewInit() {
    const width = this.useElementWidth ? this.elementRef.nativeElement.offsetWidth : this.window.innerWidth;
    this.resize$.next(width);
  }

  private getGrid(screenSize) {
    let result = gridSetup.filter(
      (setup) => screenSize > setup.breakpoints.minWidth && screenSize < setup.breakpoints.maxWidth,
    )[0];
    if (this.maxItemsPerPage && this.maxItemsPerPage < result.pagination.perPage) {
      result = gridSetup.find((setup) => setup.pagination.perPage === this.maxItemsPerPage) || result;
    }
    return result;
  }

  public getWidth(grid, screenSize) {
    const cols = 24;
    const margin = this.noMargin ? 0 : grid.margin;

    grid.bottomHeight = 151;
    grid.maxWidth = screenSize - margin;
    grid.maxWidthPad = screenSize - margin - grid.gutter;

    grid.baseWidth = grid.maxWidth / cols;

    grid.collapsedWidth = grid.units.collapsed * grid.baseWidth;

    grid.collapsedWidthInner = grid.collapsedWidth - grid.gutter;
    grid.collapsedHeight = grid.collapsedWidth - grid.gutter + grid.bottomHeight;

    grid.hoverWidthInner = grid.collapsedWidth;
    grid.hoverHeight = grid.collapsedWidth + grid.bottomHeight;

    grid.expandedWidth = grid.units.expanded * grid.baseWidth + grid.gutter;

    if (this.usePopupExpandedUnits && grid.name === 'small') {
      grid.expandedWidthPopup = (grid.units.expanded - 4) * grid.baseWidth + grid.gutter - margin;
    } else {
      grid.expandedWidthPopup = grid.expandedWidth - margin;
    }
    grid.expandedHeight = 521;

    grid.minHeight = grid.expandedHeight + 60;
    if (grid.expandedHeight < grid.hoverHeight) {
      grid.minHeight = grid.hoverHeight + 60;
    }

    grid.halfCollapsed = grid.collapsedWidth / 2;
    grid.positions = [];
    for (let i = 0; i < grid.pagination.perPage; i++) {
      const centerOfElement = i * grid.collapsedWidth + grid.halfCollapsed;
      const distanceFromCenter = grid.maxWidth / 2 - centerOfElement;
      grid.positions.push(Math.floor(distanceFromCenter));
    }

    return grid;
  }
}
