import { gql } from 'apollo-angular';

export const LANGUAGES_LIST_QUERY = gql`
  query languages {
    languages {
      label
      code
    }
  }
`;

export const GEOFENCING_DATA_QUERY = gql`
  query geofencing {
    countries {
      id
      name
      parentRegionId
      alpha2Code
    }
    regions {
      id
      name
      parentRegionId
    }
  }
`;

export const GET_COUNTRIES_QUERY = gql`
  query countries {
    countries {
      name
      alpha2Code
      currencies {
        code
        symbol
      }
    }
  }
`;

export const STATES_QUERY = gql`
  query states($countryAlpha2Code: String!, $pagination: PaginationInput) {
    states(countryAlpha2Code: $countryAlpha2Code, pagination: $pagination) {
      alpha2Code
      name
    }
  }
`;

export const EXCHANGE_MARGIN_QUERY = gql`
  query exchangeMargin {
    configurations {
      exchangeMargin
    }
  }
`;
