import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GEOFENCING_PROVIDER, GeofencingFacadeService, GeofencingGqlService, GeofencingJsonService } from './providers';

@NgModule({
  imports: [CommonModule],
  providers: [GeofencingFacadeService, GeofencingGqlService, GeofencingJsonService, GEOFENCING_PROVIDER],
})
export class GeofencingStoreModule {}
