import { Injectable } from '@angular/core';

import { AppId } from '@ultra/core/models';

import { RouteService } from '../../services/route/route.service';

export const UNIQ_FACTORY_PAGE_PATH = `product/uniqfactory`;

@Injectable({ providedIn: 'root' })
export class UniqFactoryRouteService extends RouteService {
  appId = AppId.ULTRA_UNIQ_MARKETPLACE;
  /**
   * Checks if the current app is Marketplace to decide if use the
   * Angular router or the client to navigate to the Uniq Factory Page.
   */
  openUniqFactoryPage(uniqFactoryId: string, deeplink = false) {
    const uniqFactoryPagePath = `${UNIQ_FACTORY_PAGE_PATH}/${uniqFactoryId}`;
    if (this.isSameApp()) {
      this.navigateToUniqFactoryPage(uniqFactoryId);
    } else {
      this.goToApp(this.appId, uniqFactoryPagePath, deeplink);
    }
  }

  /**
   * Use the Angular router to navigate to the Uniq Factory Page
   */
  navigateToUniqFactoryPage(uniqFactoryId: string) {
    const uniqFactoryPagePath = `/${UNIQ_FACTORY_PAGE_PATH}/${uniqFactoryId}`;
    const sameLocation = this.router.url.includes(uniqFactoryPagePath);
    this.router.navigate([uniqFactoryPagePath], {
      queryParams: {
        backUrl: sameLocation ? '/' : encodeURI(this.router.url),
      },
    });
  }

  getUniqFactoryPageDeeplink(uniqFactoryId: string): string {
    return this.deepLinkService.buildProxyDeeplinkURL(
      AppId.ULTRA_UNIQ_MARKETPLACE,
      `${UNIQ_FACTORY_PAGE_PATH}/${uniqFactoryId}`,
    );
  }
}
