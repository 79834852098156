import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { WINDOW } from '@ultra/core/providers';
import { MainStorageService, StorageKey } from '@ultra/core/services';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { ITransactionAction } from '../authenticator/interfaces/authenticator-api-input.interface';

import { TransactionAppParam } from './enums/transaction-app-params.enum';
import { TransactionParam } from './enums/transaction-params.enum';
import { IStorageTransaction, IStorageTransactionMap } from './interfaces/transaction.interface';

@Injectable({
  providedIn: 'root',
})
export class TransactionAppService {
  constructor(
    @Inject(APP_CONFIG) public readonly environment: IEnvironment,
    private mainStorageService: MainStorageService,
    @Inject(WINDOW) private window: Window
  ) {}

  openBasket(): void {
    const route = new URL(this.environment.transactionUrl);
    route.searchParams.set(TransactionAppParam.CANCEL_URL, this.window.location.href);
    this.window.location.assign(route.href);
  }

  openTransactionSigning(blockchainTransaction: ITransactionAction, data?: any): void {
    this.addTransaction(blockchainTransaction, data).subscribe((id) => {
      const route = new URL(`${this.environment.transactionUrl}/transaction`);
      route.searchParams.set(TransactionParam.ID, id);
      this.window.location.assign(route.href);
    });
  }

  openUniqPurchase(uniqFactoryId: string): void {
    const route = new URL(`${this.environment.transactionUrl}/uniq`);
    route.searchParams.set(TransactionAppParam.CANCEL_URL, this.window.location.href);
    route.searchParams.set(TransactionAppParam.UNIQ_FACTORY_ID, uniqFactoryId);
    this.window.location.assign(route.href);
  }

  /**
   * Adds a blockchain transaction to storage
   * A unique id will be generated to identify the transaction in the storage
   * @param {ITransactionAction} blockchainTransaction - raw blockchain transaction to add to storage
   * @param data - extra data to be stored and fetched in the transaction app
   */
  addTransaction(blockchainTransaction: ITransactionAction, data?: any): Observable<string> {
    return this.mainStorageService.getObject<IStorageTransactionMap>(StorageKey.TRANSACTIONS).pipe(
      switchMap((transactionMap) => {
        const id = uuidv4();
        transactionMap = transactionMap || {};
        transactionMap[id] = {
          data,
          transaction: blockchainTransaction,
          redirectUri: `${this.window.location.origin}${this.window.location.pathname}${this.window.location.search}`,
        };
        return this.mainStorageService.setObject(StorageKey.TRANSACTIONS, transactionMap).pipe(map(() => id));
      })
    );
  }

  /**
   * Gets a pending blockchain transaction from storage
   * @param {string} id - unique id of the transaction
   */
  public getTransaction(id: string): Observable<IStorageTransaction> {
    return this.mainStorageService
      .getObject<IStorageTransactionMap>(StorageKey.TRANSACTIONS)
      .pipe(map((transactions) => transactions && transactions[id]));
  }

  /**
   * Removes a pending blockchain transaction from storage
   * @param {string} id - unique id of the transaction
   */
  public removeTransaction(id: string): Observable<boolean> {
    return this.mainStorageService.getObject<IStorageTransactionMap>(StorageKey.TRANSACTIONS).pipe(
      switchMap((transactionMap) => {
        if (!transactionMap || !Object.prototype.hasOwnProperty.call(transactionMap, id)) {
          return of(false);
        }
        delete transactionMap[id];
        return this.mainStorageService.setObject(StorageKey.TRANSACTIONS, transactionMap).pipe(map(() => true));
      })
    );
  }
}
