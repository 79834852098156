import { DataSource } from '@angular/cdk/collections';
import { Store } from '@ngxs/store';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { CardCarouselParams, CarouselPaginate, PaginationDirection, PaginationState } from './card-carousel';
import {
  fillResultsWithDuplicatedData,
  fillResultsWithPlaceholders,
  paginateFirstPage,
  paginateLeft,
  paginateRight,
} from './card-carousel.helper';
export class CardCarouselDatasource<T> implements DataSource<T> {
  data$: Observable<any>;
  pagination$ = new BehaviorSubject<CarouselPaginate>(null);

  private paginationState$ = new BehaviorSubject<PaginationState>(null);
  private destroy$: Subject<void> = new Subject<void>();

  private paginationChanged$: Observable<{ data: T[]; pagination: PaginationState }> = this.paginationState$.pipe(
    distinctUntilChanged(),
    withLatestFrom(this.store.select(this.params.selector)),
    filter(([data, pagination]) => !!data && !!pagination),
    map(([pagination, data]) => ({ data, pagination })),
    takeUntil(this.destroy$),
  );

  private dataSourceChanged$: Observable<{ data: T[]; pagination: PaginationState }> = this.store
    .select(this.params.selector)
    .pipe(
      distinctUntilChanged(),
      withLatestFrom(this.paginationState$),
      filter(([data, pagination]) => !!data && !!pagination),
      // Everytime the data changes we need to set current page at 1
      map(([data, pagination]) => ({
        data,
        pagination: paginateFirstPage(pagination),
      })),
      tap(({ pagination }) => {
        this.paginationState$.next(pagination);
      }),
      takeUntil(this.destroy$),
    );

  constructor(
    protected store: Store,
    public params: CardCarouselParams<T>,
  ) {
    this.data$ = merge(this.dataSourceChanged$, this.paginationChanged$).pipe(
      map(({ data, pagination }) => {
        let result = data;
        if (data.length % pagination.perPage > 0) {
          // current carousel implementation, requirements and design force us
          // to fill the last carousel page with items from the previous page if needed
          // and make the last page full of items
          result = fillResultsWithDuplicatedData(data, pagination.perPage);
        }
        const maxPages = Math.ceil(result.length / pagination.perPage);
        if (result.length < pagination.perPage * pagination.pages) {
          result = fillResultsWithPlaceholders(result, pagination);
        }
        this.pagination$.next({ ...pagination, maxPages });
        return result.slice(pagination.startIndex, pagination.endIndex);
      }),
      takeUntil(this.destroy$),
    );
  }

  connect(): Observable<T[]> {
    return this.data$;
  }

  disconnect(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  gridPaginate(param: PaginationState): void {
    const paginateFirstPageState = paginateFirstPage(param);
    this.paginationState$.next(paginateFirstPageState);
  }

  paginate(paginationDirection: PaginationDirection): void {
    const currentPaginationState = this.paginationState$.getValue();
    const updatedPagination =
      paginationDirection === 'left' ? paginateLeft(currentPaginationState) : paginateRight(currentPaginationState);
    this.paginationState$.next(updatedPagination);
  }
}
