import { Paginatable } from '../models/base-interfaces/pagination.interface';
import { IUniq } from '../models/uniq/interfaces/uniq.interface';
import { IUniqFactory } from '../uniq-factory/uniq-factory.interface';

const verifiedCreators = [
  { blockchainId: 'ultra.nft.ft', name: 'Ultra Uniq' },
  { blockchainId: 'ultra.nft1', name: 'Arena Uniq' },
];

// we should consider zero timestamp as no date case
const ZERO_TIMESTAMP_ISO = '1970-01-01T00:00:00.000Z';

export const setUniqFactoryCreator = (uniqFactory: IUniqFactory): IUniqFactory => {
  return {
    ...uniqFactory,
    creator: {
      ...uniqFactory.creator,
      verified: isVerifiedCreator(uniqFactory.creator.blockchainId),
      eba: {
        ...uniqFactory.creator.eba,
        name: getEbaName(uniqFactory.creator.blockchainId),
      },
    },
    tradability: {
      ...uniqFactory.tradability,
      tradingEndDate: {
        ...uniqFactory.tradability?.tradingEndDate,
        onSpecificDay:
          uniqFactory.tradability?.tradingEndDate?.onSpecificDay === ZERO_TIMESTAMP_ISO
            ? null
            : uniqFactory.tradability?.tradingEndDate?.onSpecificDay,
      },
    },
    transferability: {
      ...uniqFactory.transferability,
      transferEndDate: {
        ...uniqFactory.transferability?.transferEndDate,
        onSpecificDay:
          uniqFactory.transferability?.transferEndDate?.onSpecificDay === ZERO_TIMESTAMP_ISO
            ? null
            : uniqFactory.transferability?.transferEndDate?.onSpecificDay,
      },
    },
  };
};

export const setUniqFactoryCreatorPaginatable = (
  uniqFactoryList: Paginatable<IUniqFactory[]>,
): Paginatable<IUniqFactory[]> => {
  uniqFactoryList.data = uniqFactoryList.data.map(setUniqFactoryCreator);
  return uniqFactoryList;
};

export const setUniqOwner = (uniq: IUniq): IUniq => {
  uniq.factory = setUniqFactoryCreator(uniq.factory);
  uniq.owner.eba = { ...uniq.owner.eba, name: getEbaName(uniq.owner.blockchainId) };
  return uniq;
};

export const setUniqOwnerPaginatable = (uniqList: Paginatable<IUniq[]>): Paginatable<IUniq[]> => {
  uniqList.data = uniqList.data.map(setUniqOwner);
  return uniqList;
};

/**
 * For display purposes waiting for the backend development, when the blockchainId is `ultra.nft.ft`
 * the display name should be Ultra Uniq, and for `ultra.nft1` should be Arena Uniq in other cases, the blockchainId.
 * @param blockchainId
 */
export const getEbaName = (blockchainId: string): string => {
  const creator = verifiedCreators.find((item) => item.blockchainId === blockchainId);
  return creator ? creator.name : blockchainId;
};

export const isVerifiedCreator = (blockchainId: string): boolean => {
  return verifiedCreators.some((item) => item.blockchainId === blockchainId);
};

export const isThirdPartyCreator = (blockchainId: string): boolean => {
  return blockchainId !== 'ultra.nft.ft';
};
