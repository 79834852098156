import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LanguageInterface } from '../../../interfaces';
import { IGeofencingData, IGeofencingEntity } from '../../../models';
import * as GeofencingActions from '../geofencing.actions';
import { GeofencingState } from '../geofencing.state';

import { GEOFENCING_SERVICE } from './geofencing.provider';

@Injectable()
export class GeofencingFacadeService {
  private store = inject(Store);
  private geofencingService = inject(GEOFENCING_SERVICE);

  geofencingData$: Observable<IGeofencingData>;
  geofencingCounties$: Observable<IGeofencingEntity[]>;
  geofencingCountriesMap$: Observable<{ [key: string]: IGeofencingEntity }>;

  constructor() {
    this.geofencingData$ = this.store.select(GeofencingState.getGeofencingData);

    this.geofencingCounties$ = this.store.select(GeofencingState.getGeofencingCountries);

    this.geofencingCountriesMap$ = this.store.select(GeofencingState.getGeofencingCountries).pipe(
      map((counties: IGeofencingEntity[]) =>
        counties.reduce((acc, country) => {
          return { ...acc, [country.id]: country };
        }, {}),
      ),
    );
  }

  getGeofencingData(): Observable<IGeofencingData> {
    return this.geofencingService
      .getGeofencingData()
      .pipe(tap((geofencingData: any) => this.store.dispatch(new GeofencingActions.SetGeofencingData(geofencingData))));
  }

  getLanguagesList(): Observable<LanguageInterface[]> {
    return this.geofencingService.getLanguagesList();
  }
}
