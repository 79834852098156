import { inject, Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';

import { UserFacadeService } from '@ultra/core/lib/stores';
import { CurrencyType, IUser } from '@ultra/core/models';

import { TIMEZONE_DATA } from './timezone-to-currency';

export interface ITimeZoneData {
  zoneToCountry: Record<string, string>;
  countryToCurrency: Record<string, CurrencyType>;
}

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currency$: Observable<CurrencyType> = inject(UserFacadeService).user$.pipe(
    take(1),
    map((user: IUser) => user.personalData?.countryCurrency ?? this.timeZoneToCurrency()),
  );

  private timeZoneToCurrency() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { zoneToCountry, countryToCurrency } = TIMEZONE_DATA;
    const countryCode = zoneToCountry[timeZone];
    const currencyCode = countryToCurrency[countryCode];
    return currencyCode ?? CurrencyType.USD;
  }
}
