import { inject, InjectionToken } from '@angular/core';

import { AuthService } from '@ultra/core/auth';

import { GeofencingServiceInterface } from './geofencing.service.interface';
import { GeofencingGqlService } from './geofencing-gql.service';
import { GeofencingJsonService } from './geofencing-json.service';

export const GEOFENCING_SERVICE = new InjectionToken<GeofencingServiceInterface>('GeofencingService');

export const GEOFENCING_PROVIDER = {
  provide: GEOFENCING_SERVICE,
  useFactory: (authService: AuthService) => {
    return authService.isAuthenticated() ? inject(GeofencingGqlService) : inject(GeofencingJsonService);
  },
  deps: [AuthService],
};
