import { inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';

import { IdCardEntity, IStoreTokenFactory, TokenFactoryIdCardAdapter, UniqFactoryIdCardAdapter } from '../..';
import { UniqFactoryService } from '../../uniq-factory/services/uniq-factory.service';
import { IUniqFactory } from '../../uniq-factory/uniq-factory.interface';
/*
 * A facade service for the token factory preview component
 */
@Injectable({ providedIn: 'root' })
export class TokenFactoryPreviewFacadeService {
  resalableTokenFactory = new ReplaySubject<IdCardEntity>(1);
  resalableTokenFactory$ = this.resalableTokenFactory.asObservable();

  private uniqFactoryService = inject(UniqFactoryService);

  getTokenFactoryEntity(tokenFactory: IStoreTokenFactory): IdCardEntity {
    const idCardAdapter = new TokenFactoryIdCardAdapter();
    const idCardEntity = idCardAdapter.transform(tokenFactory);
    return idCardEntity;
  }

  getUniqFactorytEntity(tokenFactory: IStoreTokenFactory): Observable<IdCardEntity> {
    const uniqIdCardAdapter = new UniqFactoryIdCardAdapter();
    const onChainId = tokenFactory.onChainId.toString();
    return this.uniqFactoryService
      .getUniqFactoryById(onChainId)
      .pipe(map((uniqFactory: IUniqFactory) => uniqIdCardAdapter.transform(uniqFactory)));
  }
}
