import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService } from '@ultra/core/graphql';

import { IUserLibraryGames } from '../..';
import { ApiSharedGqlConfig } from '../../graphql-client';
@Injectable({ providedIn: 'root' })
export class UserLibraryGqlService {
  constructor(private apiService: ApiGraphQLService) {}

  getUserLibraryGames(): Observable<IUserLibraryGames> {
    return this.apiService.getQuery(ApiSharedGqlConfig.USER_LIBRARY_GAMES, {}, { cache: false }).pipe(
      map((res) => {
        return {
          data: res.data?.userGame?.list || [],
          meta: res.data?.meta || {},
        };
      })
    );
  }
}
