import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import iassign from 'immutable-assign';

import { ImmutableSelector } from '../../helpers/immutable-selector.decorator';
import { IUser } from '../../models/user/interfaces/user.interface';

import * as UserActions from './user.actions';

const USER_INITIAL_STATE: IUser = {
  id: null,
  username: null,
  roles: null,
  status: null,
  personalData: null,
  avatar: null,
  cash: null,
  creditCards: null,
  hasKeys: false,
};

@State<IUser>({
  name: 'user',
  defaults: USER_INITIAL_STATE,
})
@Injectable()
export class UserState {
  @Selector()
  @ImmutableSelector()
  public static getUser(state: IUser) {
    return state;
  }

  @Action([UserActions.SetUser, UserActions.SetTokenUserData])
  public setUser(
    { setState, getState }: StateContext<IUser>,
    { payload }: UserActions.SetUser | UserActions.SetTokenUserData,
  ): void {
    const newState = iassign(getState(), (state) => {
      return { ...state, ...payload };
    });
    setState(newState);
  }

  @Action([UserActions.UpdateUserKeysStatus])
  public updateUserKeysStatus(
    { setState, getState }: StateContext<IUser>,
    { payload }: UserActions.UpdateUserKeysStatus,
  ) {
    const newState = iassign(getState(), (state) => {
      state.hasKeys = payload;
      return state;
    });
    setState(newState);
  }

  @Action([UserActions.UpdateBlockchainStatus])
  public updateBlockchainStatus(
    { setState, getState }: StateContext<IUser>,
    { payload }: UserActions.UpdateBlockchainStatus,
  ) {
    const newState = iassign(getState(), (state) => {
      state.blockchainId = payload.blockchainId;
      state.blockchainStatus = payload.blockchainStatus;
      state.deviceSyncStatus = payload.deviceSyncStatus;
      return state;
    });
    setState(newState);
  }

  @Action([UserActions.SetUserInitialState])
  public setUserInitialState({ setState }: StateContext<IUser>) {
    setState(USER_INITIAL_STATE);
  }
}
