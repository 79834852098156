import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import iassign from 'immutable-assign';

import { ImmutableSelector } from '@ultra/core/helpers';

import * as GeofencingActions from './geofencing.actions';

const INITIAL_STATE = {
  countries: [],
  regions: [],
};

@State({
  name: 'geofencing',
  defaults: INITIAL_STATE,
})
@Injectable()
export class GeofencingState {
  @Selector()
  @ImmutableSelector()
  public static getGeofencingData(state) {
    return state;
  }

  @Selector()
  @ImmutableSelector()
  public static getGeofencingCountries(state) {
    return state.countries;
  }

  @Action(GeofencingActions.SetGeofencingData)
  public setGeofencingData(
    { setState, getState }: StateContext<any>,
    { payload }: GeofencingActions.SetGeofencingData
  ) {
    const state = iassign(getState(), () => payload);
    setState(state);
  }
}
