<div class="properties-nav me-3">
  @if (idCardEntity?.type === 'uniq') {
    <div
      class="properties-nav-item"
      ultraAutotestAttribute="token-identity-tab"
      (click)="changeTab('identity')"
      [class.properties-nav-item--active]="isTabActive('identity')">
      <div class="properties-icon-wrap me-2">
        <i class="icon opacity-70 icon-identity"></i>
      </div>
      <span i18n>Identity</span>
    </div>
  }
  @if (idCardEntity.isTokenized) {
    <div
      class="properties-nav-item"
      ultraAutotestAttribute="token-quantity-tab"
      (click)="changeTab('quantity')"
      [class.properties-nav-item--active]="isTabActive('quantity')">
      <ultra-id-card-quantity-status [quantityAvailability]="idCardEntity?.quantity" class="me-2" />
      <span i18n>Availability</span>
    </div>
  }
  @if (idCardEntity.isTokenized) {
    <div
      class="properties-nav-item"
      ultraAutotestAttribute="token-tradability-tab"
      (click)="changeTab('tradability')"
      [class.properties-nav-item--active]="isTabActive('tradability')">
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.tradingPeriod"
        [availability]="idCardEntity?.tradingPeriod?.resaleAvailability"
        [action]="actions.TRADABILITY" />
      <span i18n>Tradability</span>
    </div>
  }

  @if (showTransferability && idCardEntity.isTokenized) {
    <div
      class="properties-nav-item"
      ultraAutotestAttribute="token-transferability-tab"
      (click)="changeTab('transferability')"
      [class.properties-nav-item--active]="isTabActive('transferability')">
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.transferPeriod"
        [availability]="idCardEntity?.transferPeriod?.transferAvailability"
        [action]="actions.TRANSFERABILITY" />
      <span i18n>Transferability</span>
    </div>
  }

  @if (showGeofencing) {
    <div
      class="properties-nav-item"
      ultraAutotestAttribute="token-geofencing-tab"
      (click)="changeTab('geofencing')"
      [class.properties-nav-item--active]="isTabActive('geofencing')">
      <div class="properties-icon-wrap me-2" [ngClass]="getAvailabilityClass()">
        @if (isGeofencingSet()) {
          <i class="icon" [ngClass]="currentUserCountry?.available ? 'icon-world' : 'icon-world-na'"></i>
        }
      </div>
      <span i18n>Geofencing</span>
    </div>
  }
  @if (idCardEntity?.metadata?.status === 'VALID' && idCardEntity?.type === 'uniq') {
    <div>
      <span class="overline-medium text-white-alpha-5" i18n>ADVANCED</span>
      @if (idCardEntity?.metadata?.status === 'VALID' && idCardEntity?.type === 'uniq') {
        <div
          class="properties-nav-item mt-1"
          ultraAutotestAttribute="data-tab"
          (click)="changeTab('data')"
          [class.properties-nav-item--active]="isTabActive('data')">
          <div class="properties-icon-wrap me-2">
            <i class="icon opacity-70 icon-code"></i>
          </div>
          <span i18n>Data</span>
        </div>
      }
    </div>
  }
</div>

<div class="properties-content">
  @switch (activeTab) {
    @case ('tradability') {
      @if (showTradability) {
        <ultra-id-card-actions
          [class.flat-card]="isFlatActionCard"
          [action]="actions.TRADABILITY"
          [period]="idCardEntity?.tradingPeriod"
          [startDate]="idCardEntity?.tradability?.tradingStartDate"
          [endDate]="idCardEntity?.tradability?.tradingEndDate"
          actionsTooltipInfo="The Tradability of a Uniq tells you if it can be resold on the Uniq Marketplace or transferred to other users.
      It may have a start date and/or an end date.
      The creator of the Uniq can set a minimum resale price."
          [availability]="idCardEntity?.tradingPeriod?.resaleAvailability"
          [revenueData]="{
            revenue: idCardEntity?.tradability?.resellRevenueShare,
            minResellPrice: idCardEntity?.tradability?.minimumResellPrice,
            minResellPriceCurrency: idCardEntity?.tradability?.minimumResellPriceCurrency
          }">
          <span title>Tradability</span>
        </ultra-id-card-actions>
      }
    }
    @case ('transferability') {
      @if (showTransferability) {
        <ultra-id-card-actions
          [action]="actions.TRANSFERABILITY"
          [period]="idCardEntity?.transferPeriod"
          [startDate]="idCardEntity?.transferability?.transferStartDate"
          [endDate]="idCardEntity?.transferability?.transferEndDate"
          actionsTooltipInfo="The Transferability of a Uniq tells you if it can be transferred or shared to another user."
          [availability]="idCardEntity?.transferPeriod?.transferAvailability"
          [class.flat-card]="isFlatActionCard">
          <span title>Transferability</span>
        </ultra-id-card-actions>
      }
    }
    @case ('identity') {
      @if (idCardEntity) {
        <ultra-id-card-identity [idCardEntity]="idCardEntity" />
      }
    }
    @case ('quantity') {
      @if (idCardEntity?.quantity) {
        <ultra-id-card-quantity [quantityAvailability]="idCardEntity?.quantity" [class.flat-card]="isFlatActionCard" />
      }
    }
    @case ('geofencing') {
      @if (isGeofencingSet()) {
        <ultra-id-card-geofencing
          [geofencing]="idCardEntity?.geofencing"
          [geofencingData]="geofencingData"
          (currentUserCountryOutput)="handleUserCurrentCountry($event)" />
      }
      @if (!isGeofencingSet()) {
        <div class="content-placeholder"></div>
      }
    }
    @case ('data') {
      @if (idCardEntity) {
        <ultra-id-card-data [idCardEntity]="idCardEntity" />
      }
    }
  }
</div>
