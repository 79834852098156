import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Placement } from '@popperjs/core';
import { Subscription } from 'rxjs';

import { ScrollDirective } from '../../../../directives/scroll/scroll.directive';
import { ISelectItem } from '../../interfaces/form-elements.interface';

import { SelectOptionDirective } from './select-option.directive';

@Component({
  selector: 'ultra-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  private subControlChanges: Subscription;
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() placeholder: string;
  @Input({ transform: booleanAttribute }) responsiveCollapse: boolean = false;
  @Input() selected: ISelectItem;
  @Input() options: ISelectItem[] = [];
  @Input() label: string;
  @Input() prefixIcon: string;
  @Input() labelIconClass: string;
  @Input() labelTooltipText: string;
  @Input() control: UntypedFormControl = new UntypedFormControl(null);
  @Input() disabled: boolean;
  @Input() scrollHeight: number = null;
  @Input() scrollToSelectedOption = false;
  @Input() dataId: string;
  @Input() placement: string | Placement | PlacementArray = ['auto'];

  @ViewChild(ScrollDirective) scrollDir: ScrollDirective;
  @ViewChildren(SelectOptionDirective)
  selectOptionsDirectives: SelectOptionDirective[];

  @Output()
  selectionChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  scrollEnd: EventEmitter<null> = new EventEmitter<null>();

  ngOnInit() {
    if (this.selected) {
      this.control.patchValue(this.selected.value, { emitEvent: false });
    }
    this.updateControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isControlChanged = changes.control && changes.control.previousValue && changes.control.currentValue;
    if (isControlChanged) {
      this.subControlChanges.unsubscribe();
      this.updateControl();
    }
  }

  ngOnDestroy() {
    if (this.subControlChanges) {
      this.subControlChanges.unsubscribe();
    }
  }

  markAsSelected(option: ISelectItem) {
    if (option.disable) {
      return;
    }
    this.selected = option;

    this.control.patchValue(option.value);
    this.selectionChange.emit(option.value);
  }

  public scrollTo(): void {
    if (this.scrollToSelectedOption && !!this.scrollDir && !!this.control.value) {
      const scrollOptionDirective: SelectOptionDirective = this.selectOptionsDirectives.find(
        (selectOptionDirective: SelectOptionDirective) => selectOptionDirective.optionValue === this.control.value,
      );
      const scrollTo: number = scrollOptionDirective.offsetTop + scrollOptionDirective.scrollHeight;
      this.scrollDir.scrollToY(scrollTo);
    }
  }

  public onScrollEnd(): void {
    this.scrollEnd.emit();
  }

  private setSelected(value) {
    this.selected = value ? this.options.find((el) => el.value === this.control.value) : null;
  }

  private updateControl() {
    this.setSelected(this.control.value);
    this.subControlChanges = this.control.valueChanges.subscribe((value) => this.setSelected(value));
  }
}
