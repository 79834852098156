import { gql } from 'apollo-angular';

import { GDC_IMAGE_WITH_ORDER_GQL, IMAGE_DETAIL_GQL, IMAGE_GQL, PRICES } from '../../shared/queries/shared.query';

export const SAVE_TOKEN_FACTORY = gql`
  mutation saveTokenFactory($tokenFactory: TokenFactoryToSave!) {
    saveTokenFactory(tokenFactory: $tokenFactory) {
      id
      blockchainId
      publisherId
      name
      description
      freeToPlay
      factoryType
      contentType{
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      content {
        gameContents {
          gameId
          publisherId
          builds {
            repositoryId
            buildId
            branches
            os
            type
          }
          components {
            repositoryId
            branches
            os
            type
          }
        }
      }
      prices ${PRICES}
      revenueShare {
        blockchainId
        percentage
        stopEarning
      }
      status
      geofencing
      multimedia {
        backGroundImage {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
        }
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
      languages {
        audio
        subtitles
        interfaces
      }
    }
  }
`;

export const PATCH_PRICE_AND_REVENUE_SHARES = gql`
  mutation patchTokenFactory($id: String!, $tokenFactory: TokenFactoryToPatch!) {
    patchTokenFactory(id: $id, tokenFactory: $tokenFactory) {
      id
      prices ${PRICES}
      status
      freeToPlay
      revenueShare {
        blockchainId
        percentage
        stopEarning
      }
    }
  }
`;

export const PATCH_PUBLISHED_PRICE_AND_REVENUE_SHARES = gql`
  mutation updateTokenFactoryPrice($id: String!, $tokenFactoryPrice: TokenFactoryUpdatePrice!) {
    updateTokenFactoryPrice(id: $id, tokenFactoryPrice: $tokenFactoryPrice) {
      prices ${PRICES}
    }
  }
`;

export const EDIT_GEOFENCING = gql`
  mutation patchTokenFactory($id: String!, $tokenFactory: TokenFactoryToPatch!) {
    patchTokenFactory(id: $id, tokenFactory: $tokenFactory) {
      id
      status
      geofencing
    }
  }
`;

export const PATCH_TOKEN_FACTORY_MEDIA = gql`
  mutation patchTokenFactory($id: String!, $tokenFactory: TokenFactoryToPatch!) {
    patchTokenFactory(id: $id, tokenFactory: $tokenFactory) {
      id
      status
      multimedia {
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
    }
  }
`;

export const PATCH_TOKEN_FACTORY = gql`
  mutation patchTokenFactory($id: String!, $tokenFactory: TokenFactoryToPatch!) {
    patchTokenFactory(id: $id, tokenFactory: $tokenFactory) {
      id
      blockchainId
      onChainId
      publisherId
      name
      description
      factoryType
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      content {
        gameContents {
          gameId
          publisherId
          builds {
            buildId
            branches
            os
            type
          }
          components {
            repositoryId
            branches
            os
            type
          }
        }
      }
      prices ${PRICES}
      revenueShare {
        blockchainId
        percentage
        stopEarning
      }
      status
      geofencing
      multimedia {
        backGroundImage {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
        }
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
      languages {
        audio
        subtitles
        interfaces
      }
    }
  }
`;

export const LEGACY_GAME_MIGRATION = gql`
  mutation legacyGameMigration($gameFactoryId: EntityId!, $uniqFactoryOnChainId: EntityOnChainId!) {
    legacyGameMigration {
      migrateLegacyGame(gameFactoryId: $gameFactoryId, uniqFactoryOnChainId: $uniqFactoryOnChainId)
    }
  }
`;

export const PUBLISH_TOKEN_FACTORY = gql`
  mutation goToLive($id: String!) {
    goToLive(id: $id) {
      id
      blockchainId
      publisherId
      name
      description
      factoryType
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      content {
        gameContents {
          gameId
          publisherId
          builds {
            buildId
            branches
          }
          components {
            repositoryId
            branches
          }
        }
      }
      prices ${PRICES}
      revenueShare {
        blockchainId
        percentage
        stopEarning
      }
      status
      geofencing
      multimedia {
        backGroundImage {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
        }
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
      }
      languages {
        audio
        subtitles
        interfaces
      }
    }
  }
`;

export const DELETE_TOKEN_FACTORY = gql`
  mutation deleteTokenFactory($id: String!) {
    deleteTokenFactory(id: $id) {
      id
    }
  }
`;

export const CLONE_GAME = gql`
  mutation cloneGame($gameId: OID!, $gameTitle: String!) {
    game {
      clone(gameId: $gameId, newTitle: $gameTitle) {
        id
        blockchainId
        developmentGame {
          title
          releaseDate {
            year
            month
            day
          }
          developerName
          editorName
          lastUpdate {
            date
          }
          multimedia {
            imageGalleryList ${IMAGE_GQL}
            boxArtImage ${IMAGE_GQL}
          }
        }
      }
    }
  }
`;

export const CREATE_GAME_FACTORY_DISCOUNT = gql`
  mutation gameFactoryDiscount(
    $gameFactoryId: OID!
    $discountBasisPoint: Int!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    gameFactoryDiscount {
      create(
        gameFactoryId: $gameFactoryId
        input: { discountBasisPoint: $discountBasisPoint, startDate: $startDate, endDate: $endDate }
      ) {
        id
      }
    }
  }
`;

export const UPDATE_GAME_FACTORY_DISCOUNT = gql`
  mutation gameFactoryDiscount($id: EntityId!, $discountBasisPoint: Int!, $startDate: DateTime!, $endDate: DateTime!) {
    gameFactoryDiscount {
      update(input: { id: $id, discountBasisPoint: $discountBasisPoint, startDate: $startDate, endDate: $endDate }) {
        id
      }
    }
  }
`;

export const DELETE_GAME_FACTORY_DISCOUNT = gql`
  mutation gameFactoryDiscount($id: OID!) {
    gameFactoryDiscount {
      deleteById(id: $id)
    }
  }
`;

export const DISABLE_GAME_FACTORY_DISCOUNT = gql`
  mutation gameDisableGameFactoryDiscountById($id: GameFactoryDiscountId!) {
    gameDisableGameFactoryDiscountById(id: $id)
  }
`;

export const SET_GAME_FACTORY_EXCLUSIVE_ACCESS = gql`
  mutation gameSetGameFactoryExclusiveAccess(
    $gameFactoryId: GameFactoryId!
    $uniqFactoryKeyIds: [EntityOnChainId!]!
    $requiredAmount: Int
  ) {
    gameSetGameFactoryExclusiveAccess(
      gameFactoryId: $gameFactoryId
      input: { requiredAmount: $requiredAmount, uniqFactoryKeyIds: $uniqFactoryKeyIds }
    )
  }
`;

export const REMOVE_GAME_FACTORY_EXCLUSIVE_ACCESS = gql`
  mutation gameRemoveGameFactoryExclusiveAccess($gameFactoryId: GameFactoryId!) {
    gameRemoveGameFactoryExclusiveAccess(gameFactoryId: $gameFactoryId)
  }
`;
