import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGql } from '../../../configs';
import { ApiGraphQLService, ApolloClientName } from '../../../graphql-client';
import { IUltraCloudAuthResponse, IUser } from '../../../models';
import { IDevice, INewUserDeviceData } from '../interfaces/device-data.interface';
import { IUserStrategy } from '../interfaces/user-strategy.interface';
import { UserToEdit } from '../interfaces/user-toedit.interface';

@Injectable({
  providedIn: 'root',
})
export class UserGqlService implements IUserStrategy {
  isPlatformBrowser = isPlatformBrowser(this.platformID);

  constructor(
    private apiService: ApiGraphQLService,
    @Inject(PLATFORM_ID) private platformID: any,
  ) {}

  getCurrentUser(): Observable<IUser> {
    return this.apiService
      .getQuery(ApiGql.CURRENT_USER, {}, { apolloClient: ApolloClientName.USERS, cache: false })
      .pipe(
        map((result) => {
          return result.data.currentUser;
        }),
      );
  }

  getCurrentUserProfileSettings(): Observable<Partial<IUser>> {
    return this.apiService
      .getQuery(ApiGql.CURRENT_USER_PROFILE_SETTINGS, {}, { apolloClient: ApolloClientName.USERS, cache: false })
      .pipe(map((result) => result?.data?.currentUser));
  }

  getCurrentUserInfo(): Observable<Partial<IUser>> {
    return this.apiService
      .getQuery(ApiGql.CURRENT_USER_INFO, {}, { apolloClient: ApolloClientName.USERS, cache: false })
      .pipe(map((result) => result?.data?.currentUser));
  }

  editUser(userToEdit: UserToEdit): Observable<Partial<IUser>> {
    return this.apiService
      .createMutation(ApiGql.EDIT_USER, { ...userToEdit }, { apolloClient: ApolloClientName.USERS })
      .pipe(map((result) => result.data.user.edit as Partial<IUser>));
  }

  addNewUserDevice(newUserDevice: INewUserDeviceData): Observable<IUser> {
    return this.apiService
      .createMutation(ApiGql.ADD_USER_DEVICE, { newUserDevice }, { apolloClient: ApolloClientName.USERS })
      .pipe(map((result) => result?.data?.addNewUserDevice));
  }

  activateUserDevice(deviceId: string): Observable<IDevice> {
    return this.apiService
      .createMutation(ApiGql.ACTIVATE_USER_DEVICE, { deviceId }, { apolloClient: ApolloClientName.USERS })
      .pipe(map((result) => result?.data?.activateUserDevice));
  }

  ultraCloudLogin(): Observable<IUltraCloudAuthResponse> {
    return this.apiService
      .createMutation(ApiGql.ULTRACLOUD_LOGIN, null, {
        apolloClient: ApolloClientName.ULTRA_CLOUD,
      })
      .pipe(map((result) => result?.data?.ultraCloudLogin));
  }

  accountStatus(): Observable<Partial<IUser>> {
    if (this.isPlatformBrowser) {
      return this.apiService
        .getSubscription(ApiGql.USER_ACCOUNT_STATUS, null, { apolloClient: ApolloClientName.USERS })
        .pipe(map((result) => result?.data?.userAccountStatus));
    } else {
      return NEVER;
    }
  }
}
