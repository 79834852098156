import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { LanguageInterface } from '../../interfaces/languages';
import { ISelectItem } from '../../modules/form';
import { GeofencingFacadeService } from '../../stores';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
  private geofencingFacadeService = inject(GeofencingFacadeService);
  public langs$: Observable<LanguageInterface[]>;

  constructor() {
    this.loadLanguages();
  }

  /**
   * Returns a formatted language list
   * for example: [ {lbl: 'English', value: 'en', dataId: 'language-code-en' } ]
   *
   * @returns {Observable<ISelectItem[]>}
   * @memberof LanguagesService
   */
  public getFormattedLanguageOptions(): Observable<ISelectItem[]> {
    return this.langs$.pipe(
      map((languages) =>
        languages.map((element) => ({
          lbl: element.label,
          value: element.code,
          dataId: `language-code-${element.code}`,
        })),
      ),
    );
  }

  private loadLanguages() {
    this.langs$ = this.geofencingFacadeService.getLanguagesList().pipe(shareReplay(1));
  }
}
