import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { IStoreTokenFactory } from '../../../models/token-factory';
import { DisplayPrices } from '../../../utils';
import { BtnSize } from '../../game/helpers/helper.enums';
import { IdCardEntity, IdCardPropertyTabs } from '../../id-card-content';
import { isPropertyLocked } from '../../id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import { TokenFactoryPreviewFacadeService } from '../token-factory-preview-facade.service';

type PriceTheme = 'purple' | 'transparent';
/**
 * A component that shows a card with Game Factory data.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-preview-token-header',
  templateUrl: './preview-token-header.component.html',
  styleUrls: ['./preview-token-header.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewTokenHeaderComponent extends DisplayPrices implements OnInit {
  @Input() companyName: string;
  @Input() gameName: string;
  @Input() btnSize: BtnSize = BtnSize.lg;
  @Input() tokenFactory: IStoreTokenFactory;
  @Input() priceTheme: PriceTheme = 'purple';
  @Input() isTokenFactoryAlreadyInLibrary = false;
  /*
   * Used as false in refund-item
   */
  @Input() showPrice = true;
  /**
   * True when the token factory is not available for purchase
   *
   * Used for Exclusive Access.
   */
  @Input() buyingOptionRestricted = false;

  @Output() priceButtonClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() propertyAccess = new EventEmitter<IdCardPropertyTabs>();

  uniqFactoryIdCardEntity: IdCardEntity;

  @HostListener('click')
  previewClick() {
    this.propertyAccess.emit(IdCardPropertyTabs.OVERVIEW);
  }

  private cdr = inject(ChangeDetectorRef);

  constructor(
    protected decimalPipe: DecimalPipe,
    @Optional() private tokenFactoryPreviewFacadeService: TokenFactoryPreviewFacadeService
  ) {
    super(decimalPipe);
  }

  ngOnInit() {
    if (this.tokenFactory.onChainId) {
      this.tokenFactoryPreviewFacadeService
        .getUniqFactorytEntity(this.tokenFactory)
        .pipe(take(1))
        .subscribe((uniqFactoryIdCardEntity) => {
          this.uniqFactoryIdCardEntity = uniqFactoryIdCardEntity;
          if (
            uniqFactoryIdCardEntity.tradability.enabled &&
            !isPropertyLocked(uniqFactoryIdCardEntity.tradingPeriod?.resaleAvailability)
          ) {
            this.tokenFactoryPreviewFacadeService.resalableTokenFactory.next(this.uniqFactoryIdCardEntity);
          }
          this.cdr.detectChanges();
        });
    }
  }
}
